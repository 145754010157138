import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
    components: {
      /**  MUI SelectMenu Paper Styling for mobile screen **/
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: "#37385f !important",
            color: "#d3d3ef !important",
            right: "20px!important",
            left: "20px!important",
            width: "calc(100% - 20px) !important",
            top: "49px !important",
            boxShadow: "none !important",
            height: "auto",
            bottom: "48px !important",
            borderRadius: "6px !important",
          },
        },
      },
      /**  MUI SelectMenu Paper Styling for mobile screen **/
      MuiMenu: {
        styleOverrides: {
          root: {
            backgroundColor: "#1f1d47 !important",
          },
        },
      },
      /**  MUI Select Menu u-List Styling for mobile screen **/
      MuiList: {
        styleOverrides: {
          root: {
            marginTop: "4px !important",
          },
        },
      },
      /**  MUI Select MenuItem Styling for mobile screen **/
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: "transparent !important",
            height: "64px !important",
          },
        },
      },
    },
  });