import {
  InterpreterObjectType,
  TransformedTemplate,
} from "hooks/useGlobalIndex/types";

export const findTxidByAlias = (
  contractTemplates: Record<string, TransformedTemplate>,
  interpreters: Record<string, InterpreterObjectType>,
  searchQuery: string
) => {
  // Check in interpreters first
  for (const [txid, details] of Object.entries(interpreters)) {
    if (
      details.alias &&
      details.alias.toLowerCase() === searchQuery.toLowerCase()
    ) {
      return txid;
    }
  }

  // Check in contractTemplates if not found in interpreters
  for (const [txid, details] of Object.entries(contractTemplates)) {
    if (
      details.alias &&
      details.alias.toLowerCase() === searchQuery.toLowerCase()
    ) {
      return txid;
    }
  }

  return null; // Return null if no match is found
};
