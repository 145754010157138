const BlockChainTicks: Record<string, string> = {
  Bitcoin: "BTC",
  BitcoinCash: "BCH",
  Ethereum: "ETH",
  Litecoin: "LTC",
  Elrond: "EGLD",
  Bnb: "BNB",
  Polygon: "MATIC",
  Kuji: "KUJI",
  Arbitrum: "ARB",
  DevnetL1A: "DevnetL1A",
  DevnetL1B: "DevnetL1B",
};
const getKeyByValue = (value: string): string | undefined => {
  const entries = Object.entries(BlockChainTicks);
  for (const [key, val] of entries) {
    if (val === value) {
      return key;
    }
  }
  return undefined; // Return undefined if value is not found
};

export default function handleWarningBarText(data: string): string {
  const tickersString =
    process.env.REACT_APP_ENABLED_NETWORKS ??
    "BTC,BCH,ETH,LTC,EGLD,BNB,Polygon,KUJI,DevnetL1A,DevnetL1B";
  const networkList: (keyof typeof BlockChainTicks)[] =
    data?.split(",").map((network) => network.trim()) || [];
  let res = "";
  networkList.filter((network: string) => {
    const bc = BlockChainTicks[network];
    if (tickersString.includes(bc)) {
      const key = getKeyByValue(bc);
      res = res + key + ", ";
    }
    return res;
  });
  return res;
}
