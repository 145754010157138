import './styles.css';
// import data from "./Data.json"
import { useState } from 'react';
import { Button, ButtonGroup, Select, MenuItem } from '@mui/material';
import { ButtonProps } from '@mui/material/Button';
import { ButtonGroupProps } from '@mui/material/ButtonGroup';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
} from 'recharts';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { CheckboxProps } from '@mui/material/Checkbox';
import InputBase from '@mui/material/InputBase';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 6,
    position: 'relative',
    backgroundColor: "transpareny",
    color:"#d3d3ef",
    border: '0px solid #ced4da',
    fontSize: 18,
    padding: '10px 26px 10px 12px',
    fontFamily: [
        "\"DINosaur Med\", \"CheyenneMed\", sans-serif!important"
    ].join(','),
    '&:focus': {
      borderRadius: 6,
    },
  },
  '& .MuiSelect-icon': {
    color:"#d3d3ef !important",
  },
}));
const rawdata = [
    {
        date: 1646826639,
        value: 0.01595
    },
    {
        date: 1646926542,
        value: 0.01524
    },
    {
        date: 1647026156,
        value: 0.01488
    },
    {
        date: 1647125910,
        value: 0.01423
    },
    {
        date: 1647225877,
        value: 0.01558
    },
    {
        date: 1647315833,
        value: 0.0212
    },
    {
        date: 1647425624,
        value: 0.019
    }
];

const CustomButtonGroup = styled(ButtonGroup)<ButtonGroupProps>(() => ({
    backgroundColor: "#4f4f72 !important",
}));
const CustomButton = styled(Button)<ButtonProps>(() => ({
    backgroundColor: "#4f4f72 !important",
    borderColor: "#4f4f72  !important",
    fontFamily: "\"DINosaur Med\", \"CheyenneMed\", sans-serif!important",

}));
const CustomCheckbox = styled(Checkbox)<CheckboxProps>(() => ({
    color: "#d3d3ef !important",
    borderColor: "#4f4f72  !important",
    fontFamily: "\"DINosaur Bd\",\"CheyenneBold\", sans-serif!important",
    '& .MuiFormControlLabel-label': {
        color: "#d3d3ef !important",
    }
}));

type GraphProps = {
    width: number;
}

const GraphsTitle = ["Cweb Price"] 

const Graph = (props: GraphProps) => {
    const { width } = props;
    // const [interval, setInterval] = useState(7);
    // const [data, setData] = useState(null);
    const [check, setCheck] = useState(true);
    const [select, setSelect] = useState(false);
    const [selectIndex, ] = useState(0);

    const [dataSize, setDataSize] = useState(7);

    //Format Data Funtion  to convert Int into Date object 
    const formatData = (rawdata: {
        date: number;
        value: number;
    }[]) =>
        rawdata.map(({ date, value }) => {
            // date -> Can be used as dataKey for XAxis
            //Further you can format the date as per your need
            const dateStr = new Date(date * 1000).toISOString();
            return ({
                date: dateStr.slice(8, 10) + "/" + dateStr.slice(5, 7) + "/" + dateStr.slice(0, 4),
                // temp -> Can be used as dataKey for Line
                value: value
            }
            )
        });
 
    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="graph_tooltip_label">{`$${payload[0].value}`}</p>
                    <p className="graph_tooltip_label">{`${label}`}</p>

                    {/* <p className="desc">Anything you want can be displayed here.</p> */}
                </div>
            );
        }
        return null;
    };

    return (
        <div id="Graph-wrapper">
        <div id="Graph-outer-container">
            <div id="Graph_button_group_container">

                <div id="Button_group_container">
                    <CustomButtonGroup variant="contained" aria-label="outlined button group">
                        <CustomButton onClick={() => setDataSize(7)} sx={{
                           color: dataSize === 7 ?   "#119DF3 !important":   undefined
                        }}>7d</CustomButton>
                        <CustomButton onClick={() => setDataSize(30)} sx={{
                           color: dataSize === 30 ?  "#119DF3 !important":   undefined
                        }}>1m</CustomButton>
                        <CustomButton onClick={() => setDataSize(90)} sx={{
                           color: dataSize === 90 ?  "#119DF3 !important":   undefined
                        }}>3m</CustomButton>
                        <CustomButton onClick={() => setDataSize(180)} sx={{
                           color: dataSize === 180 ? "#119DF3 !important":   undefined
                        }}>6m</CustomButton>
                    </CustomButtonGroup>
                </div>

                <div id="Graph-container">
                    <LineChart
                        width={width > 1140 ? 1090 : width * 4 / 5}
                        height={300}
                        data={formatData(rawdata)} 
                    >
                        <XAxis
                            tickLine={false}
                            axisLine={{ stroke: '#d3d3ef' }}
                            stroke="#d3d3ef"
                            dataKey={"date"}
                            padding={{ left: 50, right: 50 }} />

                        <YAxis
                            tickLine={false}
                            axisLine={{ stroke: '#d3d3ef' }}
                            stroke="#d3d3ef"
                            padding={{ top: 20, bottom: 30 }}
                            domain={['dataMin', 'dataMax']}
                            interval="preserveStartEnd" />

                        <Tooltip content={<CustomTooltip />} cursor={false} />
                        <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#d3d3ef"
                            activeDot={{ stroke: '#119DF3', strokeWidth: 10, r: 5 }}
                            strokeWidth={2}
                            unit="$"
                            dot={{ stroke: '#d3d3ef', strokeWidth: 6, r: 3 }}
                            isAnimationActive={false}
                        />
                    </LineChart>
                </div>
            </div>
            <FormGroup sx={{
                marginLeft: "10px",
                color: "#d3d3ef !important",
            }}>
                <FormControlLabel control={<CustomCheckbox sx={{
                    color: "#d3d3ef",
                    '&.Mui-checked': {
                        color: '#d3d3ef',
                    },
                }} defaultChecked onChange={() => setCheck(true)} checked={check}/>} label="USD" />
                <FormControlLabel onChange={() => setCheck(false)} checked={!check} control={<CustomCheckbox />} label="BTC" />
            </FormGroup>
            </div>
            <Select
                    open={select}
                      value={selectIndex}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      defaultValue={0}
                      onOpen={() => setSelect(true)}
                      onClose={() => setSelect(false)}
                      input={<BootstrapInput />}
                    >
                   {GraphsTitle.map((title, index) => {
                        return   (
                          <MenuItem
                            key={index}
                             value={index}
                          >
                            <span className="Select_Graph_item">{title}</span>
                          </MenuItem>
                        ) 
                      })}     
            </Select>
        </div>
    );
};

export default Graph;
