import { useEffect, useMemo, useState } from "react";
import Fuse, { FuseOptionKey } from "fuse.js";

type TUseSearchProperties<T> = {
  searchString: string;
  list: Array<T>;
  keys: Array<FuseOptionKey<T>>;
};

function useSearch<T = unknown>({
  list,
  searchString,
  keys,
}: TUseSearchProperties<T>): Array<T> {
  // Memoize the fuse instance to prevent recreation on every render
  const fuse = useMemo(() => {
    return new Fuse(list, {
      isCaseSensitive: true,
      includeMatches: true,
      includeScore: true,
      shouldSort: true,
      keys: keys,
    });
  }, [list, keys]); // Only recreate when 'list' or 'keys' change

  const [searchedList, setSearchedList] = useState<Array<T>>(list);

  useEffect(() => {
    if (!searchString) {
      setSearchedList(list);
      return;
    }
    const searchResult = fuse.search(searchString).map((res) => res.item);
    setSearchedList(searchResult);
  }, [list, searchString]);

  return useMemo(() => searchedList, [searchedList]);
}

export default useSearch;
