import { gql as GQL } from "graphql-request";

export const FETCH_CLAIMS_GQL = GQL`
  query FetchClaim($net: NetworkName!, $height: Int!) {
    network(net: $net) {
      fetchClaim(
        issuer: "L2BlockInfoProvider"
        firstPartOfKey: "L2BlockIdToHeightFirstPart"
        secondPartOfKey: $height
      ) {
        blockInfo {
          height
          blockHash
          time
        }
        claim {
          content {
            body
            key
          }
        }
      }
    }
  }
`;
