import {
  ApolloClient,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  split,
  TypePolicies,
} from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import assert from "assert";
import {
  REACT_APP_API_ENDPOINT,
  REACT_APP_SUBSCRIPTION_API_ENDPOINT,
  REACT_APP_WALLET_API_ENDPOINT,
  REACT_APP_WALLET_SUBSCRIPTION_API_ENDPOINT,
} from "./conf";

assert(REACT_APP_API_ENDPOINT);
assert(REACT_APP_SUBSCRIPTION_API_ENDPOINT);
assert(REACT_APP_WALLET_API_ENDPOINT);
assert(REACT_APP_WALLET_SUBSCRIPTION_API_ENDPOINT);

const makeClient = (
  httpUri: string,
  wsUri: string,
  typePolicies: TypePolicies
) => {
  const httpLink = new HttpLink({
    uri: httpUri,
  });

  const wsLink = new WebSocketLink({
    uri: wsUri,
    options: {
      reconnect: true,
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  // eslint-disable-next-line
  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  return new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache({
      resultCacheMaxSize: 5000000,
      typePolicies,
    }),
  });
};

export const defaultClient = makeClient(
  REACT_APP_API_ENDPOINT,
  REACT_APP_SUBSCRIPTION_API_ENDPOINT,
  {
    Block: {
      keyFields: ["hash"],
    },
    Transaction: {
      keyFields: ["txid"],
    },
  }
);

export const walletClient = makeClient(
  REACT_APP_WALLET_API_ENDPOINT,
  REACT_APP_WALLET_SUBSCRIPTION_API_ENDPOINT,
  {}
)
