import yaml from "js-yaml";

export const jsonToYaml = (jsonData: any): string => {
  try {
    const lines = yaml.dump(jsonData).split("\n");

    // Iterate over the lines and remove the unwanted occurrences
    const cleanedLines = lines.reduce(
      (acc: string[], line: string, index: number) => {
        if (line.includes("$serde_json::private::Number:")) {
          // Concatenate the line without the unwanted part to the previous line
          if (acc.length > 0) {
            acc[acc.length - 1] +=
              " " + line.replace("$serde_json::private::Number:", "").trim();
          }
        } else {
          acc.push(line);
        }
        return acc;
      },
      []
    );

    // Join the cleaned lines back into a single string
    return cleanedLines.join("\n");
  } catch (e) {
    console.error("Error converting JSON to YAML", e);
    return "";
  }
};
