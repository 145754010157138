import { NetworkName as gqlNetworkName } from "generated/graphql";
import { NetworkName as devNetworkName } from "generated/graphql-devnet";
import { NetworkName } from "@coinweb/claims-client";
import { BlockChains } from "utils/constants";

export const getBlockchainsByHover = (hoverString: NetworkName | string) => {
  const normalizedHoverString = String(hoverString)
    .replace(/\s+/g, "")
    .toLowerCase();
  for (const key in BlockChains) {
    if (BlockChains.hasOwnProperty(key)) {
      const blockchain =
        BlockChains[key as unknown as gqlNetworkName | devNetworkName];

      const normalizedBlockchainHover = blockchain.hover
        .replace(/\s+/g, "")
        .toLowerCase();

      if (normalizedBlockchainHover === normalizedHoverString) {
        return blockchain;
      }
      if (
        (normalizedBlockchainHover === "multiversx" &&
          normalizedHoverString === "elrond") ||
        (normalizedBlockchainHover === "multiversxtestnet" &&
          normalizedHoverString === "elrondtestnet") ||
        (normalizedBlockchainHover === "binance" &&
          normalizedHoverString === "bnb") ||
        (normalizedBlockchainHover === "binancetestnet" &&
          normalizedHoverString === "bnbtestnet")
      ) {
        return blockchain;
      }
    }
  }
};
