import "./Counter.css";

type CounterProps = {
    label: String;
    statistics: String;
}

const Counter = (props: CounterProps) => {
    const { label, statistics } = props;
    return (
        <div id="Counter-container">
            <div className="figure">{statistics}</div>
            <div className="stripe"></div>
            <div className="label">{label}</div>
        </div>
    );
};

export default Counter;     