import { Suggestions } from "utils/types";

export default function convertContractsToSuggestions(contracts: {
  [key: string]: any;
}): Suggestions[] {
  const suggestions: Suggestions[] = [];
  for (const alias in contracts) {
    const contract = contracts[alias];
    suggestions.push({
      data: contract.alias,
      dataType: "alias",
      type: "Contract",
    });

    suggestions.push({
      data: contract.details.contract_module || "",
      dataType: "tx",
      type: "Contract",
    });
  }

  return suggestions;
}
