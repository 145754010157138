import { RawContentData, ContractExecutedData } from "utils/types";
import seperateDataKeys from "utils/seperateDataKeys";

export default function createRawContentTxloadedState(
  data: any
): RawContentData {
  let sepData = seperateDataKeys(data);
  let jsonrawObj: RawContentData = { rawData: [] };

  if (sepData?.TxLoaded) {
    const txLoaded = sepData.TxLoaded[0];
    if (txLoaded?.content?.ops) {
      const ops = txLoaded?.content?.ops;
      let callOpIndex = -1;
      ops?.forEach((obj: any, index: number) => {
        const opType = Object.keys(obj)[0];
        switch (opType) {
          case "CallOp":
            callOpIndex++;
            if (!jsonrawObj?.rawData[callOpIndex]) {
              jsonrawObj.rawData[callOpIndex] = {
                index: callOpIndex,
                type: "CallOp",
                childTxs: [],
                opIndex: index,
              };
            }

            jsonrawObj.rawData[callOpIndex].callOp = obj?.CallOp;
            if (obj?.CallOp?.number_of_arguments)
              jsonrawObj.rawData[callOpIndex].numberOfArguments =
                obj.CallOp.number_of_arguments;

            break;
          case "DataOp":
            if (callOpIndex === -1) {
              callOpIndex = 0;
            }
            if (!jsonrawObj?.rawData[callOpIndex])
              jsonrawObj.rawData[callOpIndex] = {
                index: callOpIndex,
                opIndex: index,
                type: "DataOp",
                childTxs: [],
              };
            if (!jsonrawObj?.rawData[callOpIndex]?.ops)
              jsonrawObj.rawData[callOpIndex].ops = [obj.DataOp];
            else if (
              jsonrawObj.rawData[callOpIndex] &&
              jsonrawObj.rawData[callOpIndex]?.ops &&
              jsonrawObj.rawData[callOpIndex]?.numberOfArguments &&
              (jsonrawObj.rawData[callOpIndex]?.ops?.length ?? 0) <
                (jsonrawObj.rawData[callOpIndex]?.numberOfArguments ?? 3)
            ) {
              jsonrawObj.rawData[callOpIndex].ops?.push(obj.DataOp);
            }

            break;
          case "TakeOp":
            // Perform actions for TakeOp
            break;
          case "StoreOp":
            // Perform actions for StoreOp
            break;
          default:
          // Handle other types if needed
        }
      });
    }
  }

  return jsonrawObj;
}
