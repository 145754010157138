import { RawContentData, ContractExecutedData } from "utils/types";
import seperateDataKeys from "utils/seperateDataKeys";

export default function createRawContentContactExecutedState(
  jsonrawObj: RawContentData,
  data: any
): RawContentData {
  let sepData = seperateDataKeys(data);
  if (sepData?.ContractExecuted) {
    for (let k = 0; k < sepData?.ContractExecuted.length; k += 1) {
      const opInd = sepData?.ContractExecuted[k].op;

      for (let m = 0; m < jsonrawObj.rawData.length; m += 1) {
        if (jsonrawObj.rawData[m].opIndex === opInd) {
          jsonrawObj.rawData[m].childTxs?.push(
            sepData?.ContractExecuted[k] as unknown as ContractExecutedData
          );
        }
      }
    }
  }
  return jsonrawObj;
}
