import "./L2TxLogCard.css";
import "../TxCard/TxCard.css";
import { memo, useState, useEffect, useMemo } from "react";
import { Fade, Tooltip } from "@mui/material";
import { ClaimData } from "utils/types";
import { useSearchParams } from "react-router-dom";
import { NetworkName } from "../../generated/graphql";
import calculateTime from "utils/calculateTime";
import DeactiveSwipe from "../../assets/Icons/icon-swipe-deactive.svg";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";
import formatTime from "utils/formatTime";
import { convertToTimestamp } from "utils/convertToTimestamp";
import { BlockChains } from "utils/constants";
import Typography from "@mui/material/Typography";
import {
  L2TxLogMuiCard,
  L2TxLogMuiCardContent,
  L2TxLogDivider,
  MOBILE_WIDTH,
} from "components/TableContainer/CustomizedMui";
import handleTxIds from "utils/handleTxIds";
import { GqlBlockInfo, GqlIssuedClaim } from "../../generated/graphql";

import ClaimDataOpSection from "./ClaimDataOpSection";

type ClaimDataProps = {
  title: string;
  data: ClaimData;
  width: any;
  setNetwork: (n: NetworkName | undefined) => void;
};

const ClaimDataCard = (props: ClaimDataProps) => {
  const { title, data, width, setNetwork } = props;

  const [blockInfo, setBlockInfo] = useState<undefined | GqlBlockInfo>(
    undefined
  );
  const [issuedClaim, setIssuedClaim] = useState<undefined | GqlIssuedClaim>(
    undefined
  );
  const blockchain = BlockChains[data.network];
  const [timeDisplay, setTimeDisplay] = useState("");
  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [second, setSecond] = useState(0);
  const [currData, setCurrData] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (data?.data !== null && data?.data?.blockInfo && data?.data.claim) {
      setBlockInfo(data.data.blockInfo);
      setIssuedClaim(data.data.claim);
      const timestampTime = convertToTimestamp(data.data.blockInfo.time);
      const { display, h, m, s } = calculateTime(timestampTime);
      setTimeDisplay(display);
      setHour(h);
      setMinute(m);
      setSecond(s);
    }
  }, [data]);
  useEffect(() => {
    if (data?.data !== null && data?.data?.blockInfo && data?.data.claim) {
      setNetwork(undefined);
    }
  }, [data, setNetwork]);
  const formattedTime = useMemo(
    () => formatTime(hour, minute, second),
    [hour, minute, second]
  );

  const nextSlide = (len: number) => {
    setCurrData(currData === len - 1 ? len - 1 : currData + 1);
  };

  const prevSlide = () => {
    setCurrData(currData === 0 ? 0 : currData - 1);
  };
  return (
    <div
      className="L2txLogCard-container"
      style={{
        marginBottom: "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <L2TxLogMuiCard sx={{ minWidth: 100 }}>
        <L2TxLogMuiCardContent sx={{ minWidth: 100 }}>
          <div className="Center-container">
            <div
              className={width > MOBILE_WIDTH ? "Center" : "Center-mobile"}
              style={{
                position: "relative",
              }}
            >
              <Typography className="BoldFont16" align="center">
                {title}
              </Typography>
              <div className="L2TxLogCard-data-container">
                <img
                  style={{ marginTop: "10px" }}
                  id="Block-icon"
                  src={blockchain.icon}
                  alt="bc"
                />
                <Typography className="BoldFont16">
                  {blockchain.hover}
                </Typography>
              </div>
              <Typography align="center" className="BoldFont16">
                Block Height #{parseInt(blockInfo?.height, 16)}
              </Typography>

              {blockInfo?.blockHash && (
                <div className="L2TxId-row">
                  <Typography className="BoldFont16" align="left">
                    Block Hash
                  </Typography>

                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          width: "min-content !important",
                          backgroundColor: "#4f4f72 !important",
                          padding: "0px !important",
                          marginTop: "-1px !important",
                        },
                      },
                    }}
                    disableFocusListener
                    TransitionComponent={Fade}
                    title={<div id="TxId-tooltip">{blockInfo?.blockHash}</div>}
                  >
                    <Typography
                      className="BoldFont14 L2TxId-container"
                      align="center"
                      sx={{ alignContent: "center", marginLeft: "5px" }}
                      onClick={() => {
                        const newSearchParams = new URLSearchParams(
                          searchParams.toString()
                        );
                        newSearchParams.set("search", blockInfo?.blockHash);
                        setSearchParams(newSearchParams);
                      }}
                    >
                      {" "}
                      {blockInfo?.blockHash
                        ? handleTxIds(blockInfo?.blockHash)
                        : "N/A"}
                    </Typography>
                  </Tooltip>
                </div>
              )}

              {issuedClaim?.content?.body?.block_id && (
                <div className="L2TxId-row">
                  <Typography className="BoldFont16" align="left">
                    Block ID
                  </Typography>

                  {issuedClaim?.content?.body?.block_id ? (
                    <>
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              width: "min-content !important",
                              backgroundColor: "#4f4f72 !important",
                              padding: "0px !important",
                              marginTop: "-1px !important",
                            },
                          },
                        }}
                        disableFocusListener
                        TransitionComponent={Fade}
                        title={
                          <div id="TxId-tooltip">
                            {issuedClaim?.content?.body?.block_id}
                          </div>
                        }
                      >
                        <Typography
                          className="BoldFont14 L2TxId-container"
                          align="center"
                          sx={{ alignContent: "center", marginLeft: "5px" }}
                          onClick={() => {
                            const newSearchParams = new URLSearchParams(
                              searchParams.toString()
                            );
                            newSearchParams.set(
                              "search",
                              issuedClaim?.content?.body?.block_id
                            );
                            setSearchParams(newSearchParams);
                          }}
                        >
                          {handleTxIds(issuedClaim?.content?.body?.block_id)}
                        </Typography>
                      </Tooltip>
                    </>
                  ) : (
                    "N/A"
                  )}
                </div>
              )}
              {issuedClaim?.content?.feesStored && (
                <div className="L2TxId-row">
                  <Typography className="BoldFont16" align="left">
                    Stored Fees
                  </Typography>
                  <Typography
                    className="BoldFont14 L2TxId-container"
                    align="center"
                    sx={{ alignContent: "center", marginLeft: "5px" }}
                  >
                    {parseInt(issuedClaim?.content?.feesStored, 16)}
                  </Typography>
                </div>
              )}
              <Typography
                className="BoldFont14"
                sx={{ marginBottom: "0px !important" }}
                gutterBottom
              >
                {formattedTime}, {timeDisplay}
              </Typography>
            </div>
          </div>
          {issuedClaim?.content?.body?.l2_txs &&
            issuedClaim?.content?.body?.l2_txs.length > 0 && (
              <>
                <L2TxLogDivider variant="middle" />
                <div id="Carousel" style={{ textIndent: "0px !important" }}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <div className="Carousel-inner" style={{ height: "100%" }}>
                      <div
                        className="Carousel-icon-container"
                        onClick={prevSlide}
                      >
                        <div className="Carousel-icon">
                          <img
                            src={currData === 0 ? DeactiveSwipe : ActiveSwipe}
                            alt="swipe"
                          ></img>
                        </div>
                      </div>

                      <div className="Center-container">
                        <div
                          className={width > 425 ? "Center" : "Center-mobile"}
                        >
                          <div>
                            {issuedClaim?.content?.body?.l2_txs[currData]
                              ?.cweb_txid && (
                              <div className="L2TxId-row">
                                <Typography className="BoldFont16" align="left">
                                  CWEB TxId
                                </Typography>
                                <Typography
                                  className="BoldFont14 L2TxId-container"
                                  align="center"
                                  sx={{
                                    alignContent: "center",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <Tooltip
                                    componentsProps={{
                                      tooltip: {
                                        sx: {
                                          width: "min-content !important",
                                          backgroundColor: "#4f4f72 !important",
                                          padding: "0px !important",
                                          marginTop: "-1px !important",
                                        },
                                      },
                                    }}
                                    disableFocusListener
                                    TransitionComponent={Fade}
                                    title={
                                      <div id="TxId-tooltip">
                                        {
                                          issuedClaim?.content?.body?.l2_txs[
                                            currData
                                          ]?.cweb_txid
                                        }
                                      </div>
                                    }
                                  >
                                    <>
                                      {handleTxIds(
                                        issuedClaim?.content?.body?.l2_txs[
                                          currData
                                        ]?.cweb_txid
                                      )}
                                    </>
                                  </Tooltip>
                                </Typography>
                              </div>
                            )}
                            {issuedClaim.content.body.l2_txs[currData]?.cweb_tx
                              ?.content?.ops && (
                              <ClaimDataOpSection
                                data={
                                  issuedClaim.content.body.l2_txs[currData]
                                    ?.cweb_tx?.content?.ops
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="Carousel-icon-container"
                        onClick={() =>
                          nextSlide(issuedClaim?.content?.body?.l2_txs.length)
                        }
                      >
                        <div className="Carousel-icon">
                          <img
                            className="Right-icon-img"
                            src={
                              currData ===
                              issuedClaim?.content?.body?.l2_txs.length - 1
                                ? DeactiveSwipe
                                : ActiveSwipe
                            }
                            alt="swipe"
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
        </L2TxLogMuiCardContent>
      </L2TxLogMuiCard>
    </div>
  );
};
export default memo(ClaimDataCard);
