import Divider, { DividerProps } from '@mui/material/Divider';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Card, { CardProps } from '@mui/material/Card';
import CardContent, { CardContentProps } from '@mui/material/CardContent';
import Typography, { TypographyProps } from '@mui/material/Typography';

//**** ROW COMPONENTS */
/**  MUI BlockCard Card Styling  **/
export const BlockMuiCard = styled(Card)<CardProps>(() => ({

    textIndent: "0px !important",
    // maxWidth: 475, 
    height: '100%',
    backgroundImage: "linear-gradient(to bottom, #353763, #272852) !important",
    backgroundColor: "transparent",
    boxShadow: "none",
}));

/**  MUI BlockCard CardContent Styling  **/
export const BlockMuiCardContent = styled(CardContent)<CardContentProps>(() => ({
    padding: "20px !important",
    borderRadius: " 6px !important"
}));

/**  MUI BlockCard Height Typography Styling  **/
export const BlockHeightTypography = styled(Typography)<TypographyProps>(() => ({
    margin: "auto!important",
}));

/**  MUI BlockCard Coinweb Transactions Typography Styling  **/
export const CwTxnTypography = styled(Typography)<TypographyProps>(() => ({
    marginBottom: "0px !important",
    fontSize: 34,
    fontFamily: "DINosaur Med, sans-serif !important",
    fontWeight: 500,
    lineHeight: "0.71",
}));

/**  MUI BlockCard Data Typography Styling  **/
export const BlockDataTypography = styled(Typography)<TypographyProps>(() => ({
    marginBottom: "0px !important",
}));

/**  MUI BlockCard Divider Styling  **/
export const BlockDataDivider = styled(Divider)<DividerProps>(() => ({
    backgroundColor: "#4f4f72 !important",
    height: "1px !important",
    borderBottomWidth: "0px !important",
    width: "100% !important",
    marginLeft: "0px !important",
    marginRight: "0px !important",
}));

/**  MUI BlockCard Button Styling  **/
export const BlockCardButton = styled(Button)<ButtonProps>(() => ({
    borderadius: "6px!important",
    backgroundImage: "linear-gradient(to bottom, #aeaed1, #626089) !important",
    fontSize: "14px!important",
    boxShadow: "none !important",
    height: "44px!important",
    width: "100% !important",
    fontFamily: " \"DINosaur Med\", sans-serif!important",
    fontWeight: 700,
    fontStretch: "normal!important",
    fontStyle: "normal!important",
    lineHeight: "0.86!important",
    letterSpacing: "1.17px!important",
    color: "#fff!important",
}));
