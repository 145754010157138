import BigNumber from "bignumber.js";

export default function convertHextoMinUnits(hex: string): string {
  const decimalValue = new BigNumber(hex).dividedBy(
    new BigNumber(Math.pow(10, 18))
  );

  if (decimalValue.isZero()) {
    return "0 cweb";
  } else {
    return `${decimalValue.toFixed()} cwebs`;
  }
}
