import "./styles.css";
import Typography from "@mui/material/Typography";
import Check from "assets/Icons/check.svg";
import convertHextoMinUnits from "utils/convertHextoMinUnits";
import TableRowModal, { RenderParameters } from "./TableRowModal";
import LoadingBox from "../LoadingBox";
import { cweb_api, NetworkName, ContractId } from "@coinweb/claims-client";
import { ThemeProvider } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Select,
  Fade,
} from "@mui/material";
import { ClaimsByTxId } from "utils/types";
import { memo, useEffect, useState, useMemo } from "react";
import Tooltip from "@mui/material/Tooltip";
import {
  TabFormControl,
  SelectIcon,
  TabMenuDivider,
} from "../TableContainer/CustomizedMui";
import CopyIcon from "components/CopyIcon";
import handleTxIds from "utils/handleTxIds";
import { findSelectedNetworkClaims } from "utils/findSelectedNetworkClaims";
import { getBlockchainsByHover } from "utils/getBlockchainsByHover";
import DeactiveSwipe from "../../assets/Icons/icon-swipe-deactive.svg";
import ActiveSwipe from "../../assets/Icons/icon-swipe-active.svg";
import { theme } from "theme";
import { BlockChain } from "components/TableContainer/CustomizedMui";
import { REACT_APP_CWEB_API_URL } from "conf";

type TablesProps = {
  data: any;
  txid: string;
  isTemplate: boolean;
};
const getFirstTwoEntries = <T extends object>(obj: T): Partial<T> => {
  const result: Partial<T> = {};
  let count = 0;

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      (result as any)[key] = obj[key];
      count++;

      if (count === 2) break;
    }
  }

  return result;
};
export function RenderTxIdTypography(txid: string) {
  return (
    <div className="L2TxId-row">
      <Typography className="BoldFont14 L2TxId-container" align="center">
        {txid ? (
          <>
            <Tooltip
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#4f4f72 !important",
                    padding: "0px !important",
                    marginTop: "-1px !important",
                  },
                },
              }}
              disableFocusListener
              TransitionComponent={Fade}
              title={<div id="TxId-tooltip">{txid}</div>}
            >
              <div className="L2TxId-row">
                {handleTxIds(txid)}
                <CopyIcon data={txid} />
              </div>
            </Tooltip>
          </>
        ) : (
          "N/A"
        )}
      </Typography>
    </div>
  );
}
const Tables = (props: TablesProps) => {
  const { data, txid, isTemplate } = props;

  const [claims, setClaims] = useState<ClaimsByTxId>();
  const [networkClaims, setNetworkClaims] = useState<any[]>(
    new Array(data.tables.length).fill("loading")
  );
  const [displayRowModal, setDisplayRowModal] = useState<boolean>(false);
  const handleModalClose = () => {
    setDisplayRowModal(false);
  };

  const [offsetArray, setOffsetArray] = useState<number[]>(
    new Array(data.tables.length).fill(0)
  );
  const [selectedNetwork, setSelectedNetwork] = useState<number[]>(
    new Array(data.tables.length).fill(0)
  );
  const [selectedNetworkState, setSelectedNetworkState] = useState<boolean[]>(
    new Array(data.tables.length).fill(false)
  );
  const [selectBlockchainOptions, setSelectBlockchainOptions] = useState<
    BlockChain[]
  >([]);

  const cweb = useMemo(
    () => cweb_api(REACT_APP_CWEB_API_URL ? REACT_APP_CWEB_API_URL : ""),
    []
  );

  const increaseArrayBy10 = (index: number) => {
    setNetworkClaims((prevClaims) => {
      const updatedClaims = [...prevClaims];
      updatedClaims[index] = "loading";
      return updatedClaims;
    });
    setOffsetArray((prevArray) =>
      prevArray.map((num: number, i) => (i === index ? num + 10 : num))
    );
  };
  const decreaseArrayBy10 = (index: number) => {
    setNetworkClaims((prevClaims) => {
      const updatedClaims = [...prevClaims];
      updatedClaims[index] = "loading";
      return updatedClaims;
    });
    setOffsetArray((prevArray) =>
      prevArray.map((num: number, i) =>
        i === index && num - 10 < 0 ? num - 10 : num
      )
    );
  };

  useEffect(() => {
    const fetchSelectedNetworkClaims = (index: number) => {
      const result = findSelectedNetworkClaims(
        selectBlockchainOptions[selectedNetwork[index]],
        claims ? claims[txid] : undefined
      );

      setNetworkClaims((prevClaims) => {
        const newClaims = [...prevClaims];
        newClaims[index] = Array.isArray(result) ? result : [];
        return newClaims;
      });
    };
    for (let i = 0; i < data.tables.length; i++) {
      fetchSelectedNetworkClaims(i);
    }
  }, [selectBlockchainOptions, selectedNetwork, claims, txid, data.tables]);

  useEffect(() => {
    const fetchClaims = async () => {
      try {
        await cweb.update_tip();
        let newClaimsByTxId: ClaimsByTxId = {};
        for (let i = 0; i < data.tables.length; i++) {
          if (!newClaimsByTxId[txid]) {
            newClaimsByTxId[txid] = {};
          }
          const networkKeys = Object.keys(NetworkName).filter((key) =>
            isNaN(Number(key))
          );
          await Promise.all(
            (networkKeys as unknown as NetworkName[]).map(async (network) => {
              try {
                const shard = cweb.shard(
                  NetworkName[network] as unknown as NetworkName
                );

                const contract_id: ContractId =
                  "0xd4b1b18e9659bf4d7c2d496f2672e4f01db441720e3bd53ebebe506aa331e8a6";
                const contract = shard.contract(contract_id);
                const fpk = ["STATE"]; // Replace with actual `first_key` if needed
                if (fpk) {
                  const claimResult = await contract.get_all(
                    fpk,
                    offsetArray[i],
                    10
                  );

                  if (!newClaimsByTxId[txid][network]) {
                    newClaimsByTxId[txid][network] = {
                      claims: {
                        data: claimResult,
                        offset: offsetArray[i],
                      },
                    };
                  } else {
                    const existingEntry = newClaimsByTxId[txid][network];
                    if (existingEntry) {
                      existingEntry.claims.data = claimResult;
                      existingEntry.claims.offset = offsetArray[i];
                    }
                  }
                }
              } catch (error) {
                console.error(
                  `Error fetching claims for network ${network}:`,
                  error
                );
              }
            })
          );
        }
        setClaims(newClaimsByTxId);
      } catch (error) {
        console.error("Error fetching claims:", error);
      }
    };

    fetchClaims();
  }, [data.tables, offsetArray, cweb, txid]);

  useEffect(() => {
    const newBlockchainArray: BlockChain[] = [];
    let ind = 0;
    if (claims && claims[txid])
      for (const [, key] of Object.keys(claims[txid]).entries()) {
        const blockchain = getBlockchainsByHover(key);
        if (blockchain) {
          newBlockchainArray.push(blockchain);
          ind = ind + 1;
        }
        if (blockchain?.label === "BNB") {
          setSelectedNetwork(new Array(data.tables.length).fill(ind - 1));
        }
      }
    setSelectBlockchainOptions(newBlockchainArray);
  }, [setSelectBlockchainOptions, claims, txid, data.tables.length]);

  return (
    <div>
      {data.tables &&
        data.tables.map((table: any, index: number) => {
          if (
            (isTemplate && table?.availability.template) ||
            (!isTemplate && table?.availability.instance)
          )
            return (
              <div
                key={index}
                style={{
                  width: "calc(100%) - 20px",
                  backgroundColor: "#20214a",
                  borderRadius: "6px",
                  marginBottom: "20px",
                  padding: "10px",
                }}
              >
                <div className="grid_container">
                  <div className="grid_cell" style={{ margin: "10px" }}>
                    <Typography className="BoldWhiteFont14" align="left">
                      Alias
                    </Typography>
                    <div className="L2TxId-row">
                      <Typography className="BoldFont14" align="center">
                        {table?.alias ? table?.alias : "not provided"}
                      </Typography>
                    </div>
                  </div>
                  <div className="grid_cell" style={{ margin: "10px" }}>
                    <Typography className="BoldWhiteFont14" align="left">
                      Name
                    </Typography>
                    <div className="L2TxId-row">
                      <Typography className="BoldFont14" align="center">
                        {table?.name ? table?.name : "not provided"}
                      </Typography>
                    </div>
                  </div>
                  <div className="grid_cell" style={{ margin: "10px" }}>
                    <Typography className="BoldWhiteFont14" align="left">
                      Description
                    </Typography>
                    <div className="L2TxId-row">
                      <Typography className="BoldFont14" align="center">
                        {table?.description
                          ? table?.description
                          : "not provided"}
                      </Typography>
                    </div>
                  </div>
                </div>
                <TabFormControl sx={{ maxWidth: "500px" }}>
                  <ThemeProvider theme={theme}>
                    <Select
                      open={selectedNetworkState[index]}
                      value={selectedNetwork[index]}
                      onChange={(e) => {
                        setSelectedNetwork((prevArray) =>
                          prevArray.map((num, i) =>
                            i === index
                              ? (e.target.value as unknown as number)
                              : num
                          )
                        );
                      }}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                      defaultValue={0}
                      IconComponent={SelectIcon}
                      onOpen={() =>
                        setSelectedNetworkState((prevState) => {
                          const newState = [...prevState];
                          newState[index] = true;
                          return newState;
                        })
                      }
                      onClose={() =>
                        setSelectedNetworkState((prevState) => {
                          const newState = [...prevState];
                          newState[index] = false;
                          return newState;
                        })
                      }
                    >
                      {selectBlockchainOptions.map((network, ind) => {
                        return (
                          <MenuItem key={ind} value={ind}>
                            <div className="Select-item-wrapper">
                              <div
                                style={{
                                  display: "flex",
                                  marginBottom: "0px !important",
                                }}
                              >
                                <div className="Select-icon-container">
                                  <img
                                    width={29}
                                    src={network.icon}
                                    alt={network.hover}
                                  ></img>
                                </div>
                                <div
                                  className="Select-value"
                                  style={{
                                    color:
                                      ind === selectedNetwork[index]
                                        ? "#ffffff"
                                        : undefined,
                                  }}
                                >
                                  {network.hover} - {network.label}
                                </div>
                                {ind === selectedNetwork[index] && (
                                  <div
                                    style={{
                                      marginLeft: "auto",
                                      alignSelf: "center",
                                    }}
                                  >
                                    <div className="Select-icon-selected">
                                      <img
                                        style={{ width: "17px" }}
                                        src={Check}
                                        alt="check"
                                      ></img>
                                    </div>
                                  </div>
                                )}
                              </div>
                              {ind ===
                              selectBlockchainOptions.length - 1 ? null : (
                                <div id="Select-divider">
                                  <TabMenuDivider variant="middle" />
                                </div>
                              )}
                            </div>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </ThemeProvider>
                </TabFormControl>
                <div className="L2TxId-row" style={{ width: "100%" }}>
                  <TableContainer id="Block-Data-Table-Mui">
                    <Table
                      sx={{ borderColor: "red", overflow: "scroll" }}
                      aria-label="collapsible table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className="Table-Header" align="center">
                            Key
                          </TableCell>
                          <TableCell className="Table-Header" align="center">
                            Body
                          </TableCell>
                          <TableCell className="Table-Header" align="left">
                            Fees Stored
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(networkClaims[index]) ? (
                          networkClaims[index].map((row: any, idx: number) => (
                            <TableRow
                              key={idx}
                              onClick={() => setDisplayRowModal(true)}
                            >
                              <TableRowModal
                                open={displayRowModal}
                                onClose={handleModalClose}
                                data={row?.body}
                              />
                              <TableCell>
                                <div className="grid_container">
                                  <div
                                    className="grid_cell"
                                    style={{
                                      margin: "10px 10px 10px 0px",
                                      width: "max-content",
                                    }}
                                  >
                                    <Typography
                                      className="BoldWhiteFont14"
                                      align="left"
                                    >
                                      First Key Part
                                    </Typography>
                                    <div className="L2TxId-row">
                                      <Typography
                                        className="BoldFont14Hover"
                                        align="center"
                                      >
                                        {Array.isArray(row.key.first_part)
                                          ? `[${row.key.first_part.join(", ")}]`
                                          : row.key.first_part}
                                      </Typography>
                                    </div>
                                  </div>
                                  <div
                                    className="grid_cell"
                                    style={{
                                      margin: "10px 0px",
                                      width: "max-content",
                                    }}
                                  >
                                    <Typography
                                      className="BoldWhiteFont14"
                                      align="left"
                                    >
                                      Second Key Part
                                    </Typography>
                                    <div className="L2TxId-row">
                                      <Typography
                                        className="BoldFont14Hover"
                                        align="center"
                                      >
                                        {Array.isArray(row.key.second_part)
                                          ? RenderTxIdTypography(
                                              row.key.second_part[0]
                                            )
                                          : row.key.second_part}
                                      </Typography>
                                    </div>
                                  </div>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div className="grid_container">
                                  <RenderParameters
                                    data={getFirstTwoEntries(row.body)}
                                  />
                                </div>
                              </TableCell>
                              <TableCell>
                                {" "}
                                <Typography
                                  className="BoldWhiteFont14"
                                  align="left"
                                >
                                  {convertHextoMinUnits(row.fees_stored)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))
                        ) : networkClaims[index] === "loading" ? (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <LoadingBox>Loading...</LoadingBox>
                            </TableCell>
                          </TableRow>
                        ) : (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Typography
                                className="BoldWhiteFont14"
                                align="left"
                              >
                                No Claims Found
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div
                  id="Loadmore-container"
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <div
                    className="Carousel-icon-container"
                    onClick={() => decreaseArrayBy10(index)}
                  >
                    <div className="Carousel-icon">
                      <img
                        src={
                          offsetArray[index] === 0 ? DeactiveSwipe : ActiveSwipe
                        }
                        alt="swipe"
                      ></img>
                    </div>
                  </div>
                  <div
                    className="Carousel-icon-container"
                    onClick={() => increaseArrayBy10(index)}
                  >
                    <div className="Carousel-icon">
                      <img
                        className="Right-icon-img"
                        src={ActiveSwipe}
                        alt="swipe"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            );
          return undefined;
        })}
    </div>
  );
};

export default memo(Tables);
