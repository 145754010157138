import React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "../LinearProgress";

type Props = {
  width?: number;
};

const LoadingBox: React.FC<Props> = ({ width, children }) => (
  <Box
    sx={{
      width: "200px",
      margin: width && width < 425 ? "188px auto" : "140px auto",
      borderRadius: "2px",
      fontFamily: '"DINosaur Bk", "CheyenneRegular",sans-serif ',
      fontSize: "14px",
      letterSpacing: "1.4px",
      color: "#d3d3ef",
    }}
  >
    {children}
    <LinearProgress />
  </Box>
);

export default LoadingBox;
