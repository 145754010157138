import React from "react";
import {
  NetworkName,
  useBlocksSubscription,
  useNetworkAggregationsQuery,
  useNetworkLastBlocksQuery,
  NetworkAggregationsFragment,
  BlockFragment,
} from "../generated/graphql";
import {
  NetworkName as devNetworkName,
  useBlocksSubscription as devUseBlocksSubscription,
  useNetworkAggregationsQuery as devUseNetworkAggregationsQuery,
  useNetworkLastBlocksQuery as devUseNetworkLastBlocksQuery,
  NetworkAggregationsFragment as devNetworkAggregationsFragment,
  BlockFragment as devBlockFragment,
} from "../generated/graphql-devnet";

import { useArrayUndefined } from "./useArray";

export type UseNetworkState = {
  aggregations: NetworkAggregationsFragment | devNetworkAggregationsFragment;
  lastBlocks: BlockFragment[];
};

export type UseDevNetworkState = {
  aggregations: NetworkAggregationsFragment | devNetworkAggregationsFragment;
  lastBlocks: devBlockFragment[];
};

// Use a subscription to drive data fetches.
// The data fetches is divided into
// Poll aggregated network state at regular intervals.
// When the aggregated state changes, (re)load lastBlocks
// data.
export function useNetworkState(
  network: NetworkName | devNetworkName,
  size: number,
  subscribe: boolean | undefined
): "error" | "loading" | UseNetworkState {
  // As a backup, we fetch aggregations (small amount of data)
  // at regular intervals, and fetch the whole thing if the
  // serialized version changes.

  const { refetch, data, error, loading } = useNetworkAggregationsQuery({
    variables: {
      net: network as NetworkName,
    },
    // poll every minute as a backup if the subscriptions break down.
    // pollInterval: 60000,
  });
  const {
    refetch: lastBlocksRefetch,
    data: lastBlocksData,
    error: lastBlocksError,
    loading: lastBlocksLoading,
  } = useNetworkLastBlocksQuery({
    fetchPolicy: "no-cache",
    variables: {
      net: network as NetworkName,
      size,
    },
  });
  // Backup, if the network aggregations (polled) changes, fetch
  // data again.
  const aggregationStr = JSON.stringify(data?.network);
  React.useEffect(() => {
    lastBlocksRefetch();
  }, [lastBlocksRefetch, aggregationStr, network]);

  // Continuously fetch new data and update last blocks cache
  const [lastBlockHash, setLastBlockHash] = React.useState<string | undefined>(
    undefined
  );
  useBlocksSubscription({
    variables: {
      network: network as NetworkName,
    },
    skip: !subscribe,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data) {
        return;
      }
      if (data.blocks.hash !== lastBlockHash && subscribe) {
        setLastBlockHash(data.blocks.hash);
      }
    },
  });

  React.useEffect(() => {
    refetch();
    lastBlocksRefetch();
  }, [refetch, lastBlocksRefetch, lastBlockHash, network]);

  // const aggregationsStr = JSON.stringify(data?.network);
  const aggregations = React.useMemo(() => data?.network, [data?.network]);
  const lastBlocks = useArrayUndefined(lastBlocksData?.network?.lastBlocks);
  const ret: UseNetworkState | undefined = React.useMemo(() => {
    if (!aggregations || !lastBlocks) {
      return undefined;
    }
    return {
      aggregations,
      lastBlocks,
    };
  }, [aggregations, lastBlocks]);

  if (error || lastBlocksError) {
    return "error";
  }
  if (loading || lastBlocksLoading) {
    return "loading";
  }
  if (!ret) {
    return "error";
  }
  return ret;
}

export function useDevNetworkState(
  network: NetworkName | devNetworkName,
  size: number,
  subscribe: boolean | undefined
): "error" | "loading" | UseDevNetworkState | undefined {
  // As a backup, we fetch aggregations (small amount of data)
  // at regular intervals, and fetch the whole thing if the
  // serialized version changes
  const { refetch, data, error, loading } = devUseNetworkAggregationsQuery({
    variables: {
      net: network as devNetworkName,
    },
    // poll every minute as a backup if the subscriptions break down.
    // pollInterval: 60000,
  });

  const {
    refetch: lastBlocksRefetch,
    data: lastBlocksData,
    error: lastBlocksError,
    loading: lastBlocksLoading,
  } = devUseNetworkLastBlocksQuery({
    fetchPolicy: "no-cache",
    variables: {
      net: network as devNetworkName,
      size,
    },
  });

  // Backup, if the network aggregations (polled) changes, fetch
  // data again.
  const aggregationStr = JSON.stringify(data?.network);
  React.useEffect(() => {
    lastBlocksRefetch();
  }, [lastBlocksRefetch, aggregationStr, network]);

  // Continuously fetch new data and update last blocks cache
  const [lastBlockHash, setLastBlockHash] = React.useState<string | undefined>(
    undefined
  );
  devUseBlocksSubscription({
    variables: {
      network: network as NetworkName,
    },
    skip: !subscribe,
    onSubscriptionData: ({ subscriptionData: { data } }) => {
      if (!data) {
        return;
      }
      if (data.blocks.hash !== lastBlockHash && subscribe) {
        setLastBlockHash(data.blocks.hash);
      }
    },
  });
  React.useEffect(() => {
    refetch();
    lastBlocksRefetch();
  }, [refetch, lastBlocksRefetch, lastBlockHash, network]);
  // const aggregationsStr = JSON.stringify(data?.network);
  const aggregations = React.useMemo(() => data?.network, [data?.network]);
  const lastBlocks = useArrayUndefined(lastBlocksData?.network?.lastBlocks);
  const ret: UseDevNetworkState | undefined = React.useMemo(() => {
    if (!aggregations || !lastBlocks) {
      return undefined;
    }
    return {
      aggregations,
      lastBlocks,
    };
  }, [aggregations, lastBlocks]);

  if (error || lastBlocksError) {
    return "error";
  }
  if (loading || lastBlocksLoading) {
    return "loading";
  }
  if (!ret) {
    return undefined;
  }
  return ret;
}
