import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  CwebToken: { input: any; output: any; }
  GqlBlockHash: { input: any; output: any; }
  JSON: { input: any; output: any; }
  NaiveDate: { input: any; output: any; }
  NaiveDateTime: { input: any; output: any; }
};

export type AmountQgl = {
  __typename?: 'AmountQGL';
  bitcoin: Scalars['Float']['output'];
  bits: Scalars['Float']['output'];
  ether: Scalars['Float']['output'];
  expectedUnit: Scalars['Float']['output'];
  gwei: Scalars['Float']['output'];
  mBitcoin: Scalars['Float']['output'];
  satoshi: Scalars['Float']['output'];
  uBitcoin: Scalars['Float']['output'];
  wei: Scalars['Float']['output'];
};

export type BeneficiaryAmount = {
  __typename?: 'BeneficiaryAmount';
  amount: Scalars['Int']['output'];
  beneficiary: Scalars['String']['output'];
  beneficiaryRaw: Array<Scalars['Int']['output']>;
};

export type Block = {
  __typename?: 'Block';
  amountTransacted: Scalars['Float']['output'];
  hash: Scalars['ID']['output'];
  height: Scalars['Int']['output'];
  l2Tx: Array<CwebTransactionGql>;
  network: NetworkName;
  numberOfTransactions: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  time: Scalars['Int']['output'];
  tx: Array<Transaction>;
};

export type BlockAnchor = {
  __typename?: 'BlockAnchor';
  hash?: Maybe<Scalars['String']['output']>;
  height: Scalars['BigInt']['output'];
};

export type ContactExecutedGql = {
  __typename?: 'ContactExecutedGql';
  children: Array<Scalars['String']['output']>;
  contract: Scalars['String']['output'];
  directChildren: Array<Scalars['String']['output']>;
  jumpChildren: Array<JumpChildrenGql>;
  op: Scalars['Int']['output'];
};

export type ContractGql = {
  __typename?: 'ContractGql';
  contract: Scalars['String']['output'];
  parents: Array<ParentGql>;
};

export enum CwebDenomination {
  Minimal = 'MINIMAL'
}

export type CwebTransactionGql = {
  __typename?: 'CwebTransactionGQL';
  amountTransacted: Scalars['Float']['output'];
  fees: Scalars['Int']['output'];
  rawContent: Scalars['String']['output'];
  txid: Scalars['ID']['output'];
};

export type EmbeddedGql = {
  __typename?: 'EmbeddedGql';
  l1Txs: Array<Scalars['String']['output']>;
};

export type Experimental = {
  __typename?: 'Experimental';
  wrappedCwebTokenomics: WrappedCwebTokenomics;
};

export enum FiatName {
  Eur = 'EUR',
  Gbp = 'GBP',
  Jpy = 'JPY',
  Usd = 'USD'
}

export type FullTxLogGql = {
  __typename?: 'FullTxLogGql';
  blockHeight: Scalars['Int']['output'];
  execStep: Scalars['Int']['output'];
  network: NetworkName;
  rawContent?: Maybe<Array<Scalars['String']['output']>>;
  txIssuer: Scalars['String']['output'];
  txLogInfo?: Maybe<Array<TxLogInfoGql>>;
  txid: Scalars['String']['output'];
};

export type GqlBlockInfo = {
  __typename?: 'GqlBlockInfo';
  blockHash: Scalars['GqlBlockHash']['output'];
  hash: Scalars['String']['output'];
  height: Scalars['BigInt']['output'];
  time: Scalars['NaiveDateTime']['output'];
};

export type GqlClaim = {
  __typename?: 'GqlClaim';
  body: Scalars['JSON']['output'];
  feesStored: Scalars['JSON']['output'];
  key: Scalars['JSON']['output'];
};

export type GqlClaimInfo = {
  __typename?: 'GqlClaimInfo';
  blockInfo: GqlBlockInfo;
  claim: GqlIssuedClaim;
};

export type GqlCwebPriceHistoryInIntervals = {
  __typename?: 'GqlCwebPriceHistoryInIntervals';
  histCwebPricesInFiatInIntervals: Array<GqlNetworkPriceHistoryInIntervalsSet>;
  numberOfIntervalsRequested: Scalars['Int']['output'];
  numberOfIntervalsReturned: Scalars['Int']['output'];
  reportedInterval: HistoryInterval;
  requestedFiat: FiatName;
  requestedHistDaysBack: Scalars['Int']['output'];
  timestampUpdatingPrices: Scalars['NaiveDateTime']['output'];
  utcDatetimeNow: Scalars['NaiveDateTime']['output'];
};

export type GqlCwebUsdHistory = {
  __typename?: 'GqlCwebUsdHistory';
  currentCwebPriceInFiat: Scalars['Float']['output'];
  historyCwebPricesFromToday: Array<GqlCwebUsdPrice>;
  numCurrentPriceSourcesCwebUsd: Scalars['Int']['output'];
  numHistoryPriceSourcesCwebUsd: Scalars['Int']['output'];
  requestedFiat: FiatName;
  requestedNumHistoryDays: Scalars['Int']['output'];
  timestampUpdatingCurrentPrices: Scalars['NaiveDateTime']['output'];
  timestampUpdatingHistoricalPrices: Scalars['NaiveDateTime']['output'];
  utcDatetimeNow: Scalars['NaiveDateTime']['output'];
};

export type GqlCwebUsdPrice = {
  __typename?: 'GqlCwebUsdPrice';
  cwebPriceInUsd: Scalars['Float']['output'];
  forUtcDate: Scalars['NaiveDate']['output'];
};

export type GqlDateTimeRange = {
  from?: InputMaybe<Scalars['NaiveDateTime']['input']>;
  to?: InputMaybe<Scalars['NaiveDateTime']['input']>;
};

export type GqlDbClaimsPage = {
  __typename?: 'GqlDbClaimsPage';
  claims: Array<GqlClaimInfo>;
  hasNextPage: Scalars['Boolean']['output'];
  nextPageAnchor: BlockAnchor;
};

export type GqlFiatRateInfo = {
  __typename?: 'GqlFiatRateInfo';
  fiat: FiatName;
  fiatToUsd: Scalars['Float']['output'];
  numRateSources: Scalars['Int']['output'];
};

export type GqlFiatRates = {
  __typename?: 'GqlFiatRates';
  allFiatRates: Array<GqlFiatRateInfo>;
  requestedFiat: FiatName;
  requestedFiatToUsdRate: Scalars['Float']['output'];
  timestampUpdatingCurrentPrices: Scalars['NaiveDateTime']['output'];
};

export type GqlIssuedClaim = {
  __typename?: 'GqlIssuedClaim';
  content: GqlClaim;
  issuer: Scalars['JSON']['output'];
};

export type GqlNetworkPriceHistory = {
  __typename?: 'GqlNetworkPriceHistory';
  histL1PricesFromToday: Array<GqlNetworkPriceHistorySet>;
  numHistoryPriceSourcesCwebL1: Scalars['Int']['output'];
  numHistoryPriceSourcesL1Usd: Scalars['Int']['output'];
  requestedNetwork: NetworkName;
  requestedNumHistoryDays: Scalars['Int']['output'];
  timestampUpdatingPrices: Scalars['NaiveDateTime']['output'];
  utcDatetimeNow: Scalars['NaiveDateTime']['output'];
};

export type GqlNetworkPriceHistoryInIntervals = {
  __typename?: 'GqlNetworkPriceHistoryInIntervals';
  histL1PricesInFiatInIntervals: Array<GqlNetworkPriceHistoryInIntervalsSet>;
  numberOfIntervalsRequested: Scalars['Int']['output'];
  numberOfIntervalsReturned: Scalars['Int']['output'];
  reportedInterval: HistoryInterval;
  requestedFiat: FiatName;
  requestedHistDaysBack: Scalars['Int']['output'];
  requestedNetwork: NetworkName;
  timestampUpdatingPrices: Scalars['NaiveDateTime']['output'];
  utcDatetimeNow: Scalars['NaiveDateTime']['output'];
};

export type GqlNetworkPriceHistoryInIntervalsSet = {
  __typename?: 'GqlNetworkPriceHistoryInIntervalsSet';
  cryptoPriceInFiat: Scalars['Float']['output'];
  intervalTimestampUtc: Scalars['NaiveDateTime']['output'];
};

export type GqlNetworkPriceHistorySet = {
  __typename?: 'GqlNetworkPriceHistorySet';
  cwebPriceInL1: Scalars['Float']['output'];
  forUtcDate: Scalars['NaiveDate']['output'];
  l1PriceInCweb: Scalars['Float']['output'];
  l1PriceInUsd: Scalars['Float']['output'];
};

export type GqlNetworkPriceSet = {
  __typename?: 'GqlNetworkPriceSet';
  cwebPriceInL1: Scalars['Float']['output'];
  l1Network: NetworkName;
  l1NetworkPriceInCweb: Scalars['Float']['output'];
  l1NetworkPriceInRequestedFiat: Scalars['Float']['output'];
  numCurrentPriceSourcesCwebL1: Scalars['Int']['output'];
  numCurrentPriceSourcesL1Usd: Scalars['Int']['output'];
  timestampUpdatingPrices: Scalars['NaiveDateTime']['output'];
};

export type GqlNetworkPrices = {
  __typename?: 'GqlNetworkPrices';
  currentPrices: Array<GqlNetworkPriceSet>;
  requestedFiat: FiatName;
};

export enum HistoryInterval {
  Hour_1 = 'HOUR_1',
  Hour_24 = 'HOUR_24',
  Minute_15 = 'MINUTE_15',
  Unset = 'UNSET'
}

export type JumpChildrenGql = {
  __typename?: 'JumpChildrenGql';
  l2Txid: Scalars['String']['output'];
  toShard: NetworkName;
};

export type LegacyCwebTxLoadedGql = {
  __typename?: 'LegacyCwebTxLoadedGql';
  amount: Scalars['CwebToken']['output'];
  content: Scalars['String']['output'];
  l1Txid: Scalars['String']['output'];
};

export type Network = {
  __typename?: 'Network';
  countClaims: Scalars['Int']['output'];
  fetchClaim?: Maybe<GqlClaimInfo>;
  fetchClaimHistory: GqlDbClaimsPage;
  fetchClaims: GqlDbClaimsPage;
  lastBlocks: Array<Block>;
  totalAmountTransactedCweb: Scalars['Float']['output'];
  totalAmountTransactedL1: Scalars['Float']['output'];
  totalAmountTransactedL1New: AmountQgl;
  totalNumberOfBlocks: Scalars['Int']['output'];
  totalNumberOfCwebTxs: Scalars['Int']['output'];
  totalNumberOfL1Txs: Scalars['Int']['output'];
};


export type NetworkCountClaimsArgs = {
  datetimeRange: GqlDateTimeRange;
  firstPartOfKey: Scalars['JSON']['input'];
  issuer: Scalars['JSON']['input'];
  nextPageAnchor?: InputMaybe<Scalars['JSON']['input']>;
};


export type NetworkFetchClaimArgs = {
  firstPartOfKey: Scalars['JSON']['input'];
  issuer: Scalars['JSON']['input'];
  secondPartOfKey: Scalars['JSON']['input'];
};


export type NetworkFetchClaimHistoryArgs = {
  datetimeRange: GqlDateTimeRange;
  firstPartOfKey: Scalars['JSON']['input'];
  issuer: Scalars['JSON']['input'];
  secondPartOfKey: Scalars['JSON']['input'];
};


export type NetworkFetchClaimsArgs = {
  datetimeRange: GqlDateTimeRange;
  firstPartOfKey: Scalars['JSON']['input'];
  issuer: Scalars['JSON']['input'];
  maxClaimsToReturn: Scalars['Int']['input'];
  nextPageAnchor?: InputMaybe<Scalars['JSON']['input']>;
};


export type NetworkLastBlocksArgs = {
  suggestedAmount?: Scalars['Int']['input'];
};


export type NetworkTotalAmountTransactedCwebArgs = {
  denomination?: CwebDenomination;
};

export enum NetworkName {
  Arbitrum = 'ARBITRUM',
  ArbitrumTestnet = 'ARBITRUM_TESTNET',
  Bitcoin = 'BITCOIN',
  BitcoinCash = 'BITCOIN_CASH',
  BitcoinCashTestnet = 'BITCOIN_CASH_TESTNET',
  BitcoinTestnet = 'BITCOIN_TESTNET',
  Bnb = 'BNB',
  BnbTestnet = 'BNB_TESTNET',
  DevnetL1A = 'DEVNET_L1A',
  DevnetL1B = 'DEVNET_L1B',
  Dogecoin = 'DOGECOIN',
  DogecoinTestnet = 'DOGECOIN_TESTNET',
  Elrond = 'ELROND',
  ElrondTestnet = 'ELROND_TESTNET',
  Ethereum = 'ETHEREUM',
  EthereumTestnet = 'ETHEREUM_TESTNET',
  Kuji = 'KUJI',
  KujiTestnet = 'KUJI_TESTNET',
  Litecoin = 'LITECOIN',
  LitecoinTestnet = 'LITECOIN_TESTNET',
  Polygon = 'POLYGON',
  PolygonTestnet = 'POLYGON_TESTNET'
}

export enum NetworkStatus {
  Any = 'ANY',
  Connected = 'CONNECTED'
}

export type ParentGql = {
  __typename?: 'ParentGql';
  fromShard?: Maybe<NetworkName>;
  l2Txid: Scalars['String']['output'];
  opPosition: Scalars['Int']['output'];
};

export type QueryRootExplorer = {
  __typename?: 'QueryRootExplorer';
  block?: Maybe<Block>;
  blockByHeight?: Maybe<Block>;
  experimental: Experimental;
  getAllNetworksCurrentPrices: GqlNetworkPrices;
  getCurrentFiatToUsdRates: GqlFiatRates;
  getCwebPricesInFiat: GqlCwebUsdHistory;
  getHistoryPricesForCwebInPeriods: GqlCwebPriceHistoryInIntervals;
  getHistoryPricesForL1Network: GqlNetworkPriceHistory;
  getHistoryPricesForL1NetworkInPeriods: GqlNetworkPriceHistoryInIntervals;
  getNetworksByStatus: Array<NetworkName>;
  l2TxFullLog?: Maybe<Array<FullTxLogGql>>;
  lastBlocks?: Maybe<Array<Block>>;
  network?: Maybe<Network>;
};


export type QueryRootExplorerBlockArgs = {
  hash: Scalars['String']['input'];
  network: NetworkName;
};


export type QueryRootExplorerBlockByHeightArgs = {
  height: Scalars['Int']['input'];
  network: NetworkName;
};


export type QueryRootExplorerGetAllNetworksCurrentPricesArgs = {
  requestedFiat: FiatName;
};


export type QueryRootExplorerGetCurrentFiatToUsdRatesArgs = {
  requestedFiat: FiatName;
};


export type QueryRootExplorerGetCwebPricesInFiatArgs = {
  requestedFiat: FiatName;
  requestedNumHistoryDays: Scalars['Int']['input'];
};


export type QueryRootExplorerGetHistoryPricesForCwebInPeriodsArgs = {
  requestedFiat: FiatName;
  requestedNumHistoryDays: Scalars['Int']['input'];
};


export type QueryRootExplorerGetHistoryPricesForL1NetworkArgs = {
  requestedNetwork: NetworkName;
  requestedNumHistoryDays: Scalars['Int']['input'];
};


export type QueryRootExplorerGetHistoryPricesForL1NetworkInPeriodsArgs = {
  requestedFiat: FiatName;
  requestedNetwork: NetworkName;
  requestedNumHistoryDays: Scalars['Int']['input'];
};


export type QueryRootExplorerGetNetworksByStatusArgs = {
  status: NetworkStatus;
};


export type QueryRootExplorerL2TxFullLogArgs = {
  network: NetworkName;
  txid: Scalars['String']['input'];
};


export type QueryRootExplorerLastBlocksArgs = {
  network: NetworkName;
  size?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryRootExplorerNetworkArgs = {
  net: NetworkName;
};

export enum ReleaseType {
  CompanyReserve = 'COMPANY_RESERVE',
  FoundersAndEarlyContributors = 'FOUNDERS_AND_EARLY_CONTRIBUTORS',
  MiningReserve = 'MINING_RESERVE',
  PrivateRound = 'PRIVATE_ROUND',
  PublicSale = 'PUBLIC_SALE',
  SeedRound = 'SEED_ROUND',
  StrategicPartners = 'STRATEGIC_PARTNERS',
  StrategicRound = 'STRATEGIC_ROUND',
  TeamAndAdvisors = 'TEAM_AND_ADVISORS'
}

export type ReleaseTypeAmount = {
  __typename?: 'ReleaseTypeAmount';
  amount: Scalars['Int']['output'];
  releaseType: ReleaseType;
};

export type ResolvedOpsGql = {
  __typename?: 'ResolvedOpsGql';
  blocks: Scalars['JSON']['output'];
  reads: Scalars['JSON']['output'];
  takes: Scalars['JSON']['output'];
};

export type Subscription = {
  __typename?: 'Subscription';
  blocks: Block;
};


export type SubscriptionBlocksArgs = {
  network: NetworkName;
};

export type Transaction = {
  __typename?: 'Transaction';
  amountTransacted: Scalars['Float']['output'];
  network: NetworkName;
  txid: Scalars['ID']['output'];
};

export type TxLoadedGql = {
  __typename?: 'TxLoadedGql';
  content: Scalars['String']['output'];
  parents: TxParentsGql;
};

export type TxLogErrorGql = {
  __typename?: 'TxLogErrorGql';
  error: Scalars['String']['output'];
};

export type TxLogEventGql = {
  __typename?: 'TxLogEventGql';
  event: Scalars['String']['output'];
};

export type TxLogInfoGql = ContactExecutedGql | GqlClaim | LegacyCwebTxLoadedGql | ResolvedOpsGql | TxLoadedGql | TxLogErrorGql | TxLogEventGql;

export type TxParentsGql = ContractGql | EmbeddedGql;

export type WrappedCwebTokenomics = {
  __typename?: 'WrappedCwebTokenomics';
  circulatingSupply: Scalars['Int']['output'];
  circulatingSupplyBooked: Scalars['Int']['output'];
  circulatingSupplyByBeneficiary: Array<BeneficiaryAmount>;
  circulatingSupplyByReleaseType: Array<ReleaseTypeAmount>;
  circulatingSupplyImmediateSends: Scalars['Int']['output'];
  circulatingSupplyV2: Scalars['Int']['output'];
  maxCirculatingSupply: Scalars['Int']['output'];
  maxCirculatingSupplyBooked: Scalars['Int']['output'];
  maxCirculatingSupplyByReleaseType: Array<ReleaseTypeAmount>;
  tokensBookedByBeneficiary: Array<BeneficiaryAmount>;
  tokensBookedByReleaseType: Array<ReleaseTypeAmount>;
  totalSupply: Scalars['Int']['output'];
};

export type BlockFragment = { __typename?: 'Block', hash: string, height: number, time: number, amountTransacted: number, size: number, numberOfTransactions: number, network: NetworkName, tx: Array<{ __typename?: 'Transaction', txid: string, amountTransacted: number, network: NetworkName }>, l2Tx: Array<{ __typename?: 'CwebTransactionGQL', rawContent: string, txid: string, fees: number, amountTransacted: number }> };

export type L2TxFragment = { __typename?: 'CwebTransactionGQL', rawContent: string, txid: string, fees: number, amountTransacted: number };

export type TxFragment = { __typename?: 'Transaction', txid: string, amountTransacted: number, network: NetworkName };

export type BlockQueryVariables = Exact<{
  hash: Scalars['String']['input'];
  network: NetworkName;
}>;


export type BlockQuery = { __typename?: 'QueryRootExplorer', block?: { __typename?: 'Block', hash: string, height: number, time: number, amountTransacted: number, size: number, numberOfTransactions: number, network: NetworkName, tx: Array<{ __typename?: 'Transaction', txid: string, amountTransacted: number, network: NetworkName }>, l2Tx: Array<{ __typename?: 'CwebTransactionGQL', rawContent: string, txid: string, fees: number, amountTransacted: number }> } | null };

export type BlockByHeightQueryVariables = Exact<{
  height: Scalars['Int']['input'];
  network: NetworkName;
}>;


export type BlockByHeightQuery = { __typename?: 'QueryRootExplorer', blockByHeight?: { __typename?: 'Block', hash: string, height: number, time: number, amountTransacted: number, size: number, numberOfTransactions: number, network: NetworkName, tx: Array<{ __typename?: 'Transaction', txid: string, amountTransacted: number, network: NetworkName }>, l2Tx: Array<{ __typename?: 'CwebTransactionGQL', rawContent: string, txid: string, fees: number, amountTransacted: number }> } | null };

export type BlocksSubscriptionVariables = Exact<{
  network: NetworkName;
}>;


export type BlocksSubscription = { __typename?: 'Subscription', blocks: { __typename?: 'Block', hash: string } };

export type LastBlocksQueryVariables = Exact<{
  network: NetworkName;
  size: Scalars['Int']['input'];
}>;


export type LastBlocksQuery = { __typename?: 'QueryRootExplorer', lastBlocks?: Array<{ __typename?: 'Block', hash: string, height: number, time: number, amountTransacted: number, size: number, numberOfTransactions: number, network: NetworkName, tx: Array<{ __typename?: 'Transaction', txid: string, amountTransacted: number, network: NetworkName }>, l2Tx: Array<{ __typename?: 'CwebTransactionGQL', rawContent: string, txid: string, fees: number, amountTransacted: number }> }> | null };

export type NetworkAggregationsFragment = { __typename?: 'Network', totalNumberOfL1Txs: number, totalNumberOfCwebTxs: number, totalNumberOfBlocks: number, totalAmountTransactedCweb: number, totalAmountTransactedL1New: { __typename?: 'AmountQGL', bitcoin: number } };

export type NetworkAggregationsQueryVariables = Exact<{
  net: NetworkName;
}>;


export type NetworkAggregationsQuery = { __typename?: 'QueryRootExplorer', network?: { __typename?: 'Network', totalNumberOfL1Txs: number, totalNumberOfCwebTxs: number, totalNumberOfBlocks: number, totalAmountTransactedCweb: number, totalAmountTransactedL1New: { __typename?: 'AmountQGL', bitcoin: number } } | null };

export type NetworkLastBlocksFragment = { __typename?: 'Network', lastBlocks: Array<{ __typename?: 'Block', hash: string, height: number, time: number, amountTransacted: number, size: number, numberOfTransactions: number, network: NetworkName, tx: Array<{ __typename?: 'Transaction', txid: string, amountTransacted: number, network: NetworkName }>, l2Tx: Array<{ __typename?: 'CwebTransactionGQL', rawContent: string, txid: string, fees: number, amountTransacted: number }> }> };

export type NetworkLastBlocksQueryVariables = Exact<{
  net: NetworkName;
  size: Scalars['Int']['input'];
}>;


export type NetworkLastBlocksQuery = { __typename?: 'QueryRootExplorer', network?: { __typename?: 'Network', lastBlocks: Array<{ __typename?: 'Block', hash: string, height: number, time: number, amountTransacted: number, size: number, numberOfTransactions: number, network: NetworkName, tx: Array<{ __typename?: 'Transaction', txid: string, amountTransacted: number, network: NetworkName }>, l2Tx: Array<{ __typename?: 'CwebTransactionGQL', rawContent: string, txid: string, fees: number, amountTransacted: number }> }> } | null };

export const NetworkAggregationsFragmentDoc = gql`
    fragment NetworkAggregations on Network {
  totalNumberOfL1Txs
  totalNumberOfCwebTxs
  totalNumberOfBlocks
  totalAmountTransactedL1New {
    bitcoin
  }
  totalAmountTransactedCweb
}
    `;
export const TxFragmentDoc = gql`
    fragment Tx on Transaction {
  txid
  amountTransacted
  network
}
    `;
export const L2TxFragmentDoc = gql`
    fragment L2Tx on CwebTransactionGQL {
  rawContent
  txid
  fees
  amountTransacted
}
    `;
export const BlockFragmentDoc = gql`
    fragment Block on Block {
  hash
  height
  time
  amountTransacted
  size
  numberOfTransactions
  network
  tx {
    ...Tx
  }
  l2Tx {
    ...L2Tx
  }
}
    ${TxFragmentDoc}
${L2TxFragmentDoc}`;
export const NetworkLastBlocksFragmentDoc = gql`
    fragment NetworkLastBlocks on Network {
  lastBlocks(suggestedAmount: $size) {
    ...Block
  }
}
    ${BlockFragmentDoc}`;
export const BlockDocument = gql`
    query Block($hash: String!, $network: NetworkName!) {
  block(hash: $hash, network: $network) {
    ...Block
  }
}
    ${BlockFragmentDoc}`;

/**
 * __useBlockQuery__
 *
 * To run a query within a React component, call `useBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockQuery({
 *   variables: {
 *      hash: // value for 'hash'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useBlockQuery(baseOptions: Apollo.QueryHookOptions<BlockQuery, BlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockQuery, BlockQueryVariables>(BlockDocument, options);
      }
export function useBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockQuery, BlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockQuery, BlockQueryVariables>(BlockDocument, options);
        }
export type BlockQueryHookResult = ReturnType<typeof useBlockQuery>;
export type BlockLazyQueryHookResult = ReturnType<typeof useBlockLazyQuery>;
export type BlockQueryResult = Apollo.QueryResult<BlockQuery, BlockQueryVariables>;
export const BlockByHeightDocument = gql`
    query BlockByHeight($height: Int!, $network: NetworkName!) {
  blockByHeight(height: $height, network: $network) {
    ...Block
  }
}
    ${BlockFragmentDoc}`;

/**
 * __useBlockByHeightQuery__
 *
 * To run a query within a React component, call `useBlockByHeightQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlockByHeightQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlockByHeightQuery({
 *   variables: {
 *      height: // value for 'height'
 *      network: // value for 'network'
 *   },
 * });
 */
export function useBlockByHeightQuery(baseOptions: Apollo.QueryHookOptions<BlockByHeightQuery, BlockByHeightQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlockByHeightQuery, BlockByHeightQueryVariables>(BlockByHeightDocument, options);
      }
export function useBlockByHeightLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlockByHeightQuery, BlockByHeightQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlockByHeightQuery, BlockByHeightQueryVariables>(BlockByHeightDocument, options);
        }
export type BlockByHeightQueryHookResult = ReturnType<typeof useBlockByHeightQuery>;
export type BlockByHeightLazyQueryHookResult = ReturnType<typeof useBlockByHeightLazyQuery>;
export type BlockByHeightQueryResult = Apollo.QueryResult<BlockByHeightQuery, BlockByHeightQueryVariables>;
export const BlocksDocument = gql`
    subscription Blocks($network: NetworkName!) {
  blocks(network: $network) {
    hash
  }
}
    `;

/**
 * __useBlocksSubscription__
 *
 * To run a query within a React component, call `useBlocksSubscription` and pass it any options that fit your needs.
 * When your component renders, `useBlocksSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlocksSubscription({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useBlocksSubscription(baseOptions: Apollo.SubscriptionHookOptions<BlocksSubscription, BlocksSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<BlocksSubscription, BlocksSubscriptionVariables>(BlocksDocument, options);
      }
export type BlocksSubscriptionHookResult = ReturnType<typeof useBlocksSubscription>;
export type BlocksSubscriptionResult = Apollo.SubscriptionResult<BlocksSubscription>;
export const LastBlocksDocument = gql`
    query LastBlocks($network: NetworkName!, $size: Int!) {
  lastBlocks(network: $network, size: $size) {
    ...Block
  }
}
    ${BlockFragmentDoc}`;

/**
 * __useLastBlocksQuery__
 *
 * To run a query within a React component, call `useLastBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useLastBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLastBlocksQuery({
 *   variables: {
 *      network: // value for 'network'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useLastBlocksQuery(baseOptions: Apollo.QueryHookOptions<LastBlocksQuery, LastBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LastBlocksQuery, LastBlocksQueryVariables>(LastBlocksDocument, options);
      }
export function useLastBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LastBlocksQuery, LastBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LastBlocksQuery, LastBlocksQueryVariables>(LastBlocksDocument, options);
        }
export type LastBlocksQueryHookResult = ReturnType<typeof useLastBlocksQuery>;
export type LastBlocksLazyQueryHookResult = ReturnType<typeof useLastBlocksLazyQuery>;
export type LastBlocksQueryResult = Apollo.QueryResult<LastBlocksQuery, LastBlocksQueryVariables>;
export const NetworkAggregationsDocument = gql`
    query NetworkAggregations($net: NetworkName!) {
  network(net: $net) {
    ...NetworkAggregations
  }
}
    ${NetworkAggregationsFragmentDoc}`;

/**
 * __useNetworkAggregationsQuery__
 *
 * To run a query within a React component, call `useNetworkAggregationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkAggregationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkAggregationsQuery({
 *   variables: {
 *      net: // value for 'net'
 *   },
 * });
 */
export function useNetworkAggregationsQuery(baseOptions: Apollo.QueryHookOptions<NetworkAggregationsQuery, NetworkAggregationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkAggregationsQuery, NetworkAggregationsQueryVariables>(NetworkAggregationsDocument, options);
      }
export function useNetworkAggregationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkAggregationsQuery, NetworkAggregationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkAggregationsQuery, NetworkAggregationsQueryVariables>(NetworkAggregationsDocument, options);
        }
export type NetworkAggregationsQueryHookResult = ReturnType<typeof useNetworkAggregationsQuery>;
export type NetworkAggregationsLazyQueryHookResult = ReturnType<typeof useNetworkAggregationsLazyQuery>;
export type NetworkAggregationsQueryResult = Apollo.QueryResult<NetworkAggregationsQuery, NetworkAggregationsQueryVariables>;
export const NetworkLastBlocksDocument = gql`
    query NetworkLastBlocks($net: NetworkName!, $size: Int!) {
  network(net: $net) {
    ...NetworkLastBlocks
  }
}
    ${NetworkLastBlocksFragmentDoc}`;

/**
 * __useNetworkLastBlocksQuery__
 *
 * To run a query within a React component, call `useNetworkLastBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useNetworkLastBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNetworkLastBlocksQuery({
 *   variables: {
 *      net: // value for 'net'
 *      size: // value for 'size'
 *   },
 * });
 */
export function useNetworkLastBlocksQuery(baseOptions: Apollo.QueryHookOptions<NetworkLastBlocksQuery, NetworkLastBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NetworkLastBlocksQuery, NetworkLastBlocksQueryVariables>(NetworkLastBlocksDocument, options);
      }
export function useNetworkLastBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NetworkLastBlocksQuery, NetworkLastBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NetworkLastBlocksQuery, NetworkLastBlocksQueryVariables>(NetworkLastBlocksDocument, options);
        }
export type NetworkLastBlocksQueryHookResult = ReturnType<typeof useNetworkLastBlocksQuery>;
export type NetworkLastBlocksLazyQueryHookResult = ReturnType<typeof useNetworkLastBlocksLazyQuery>;
export type NetworkLastBlocksQueryResult = Apollo.QueryResult<NetworkLastBlocksQuery, NetworkLastBlocksQueryVariables>;