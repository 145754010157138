import LP from "@mui/material/LinearProgress";

const LinearProgress = () => (
  <LP
    sx={{
      backgroundColor: "#4f4f72",
      color: "rgb(0, 195, 255)",
      marginTop: "15px",
    }}
  />
);

export default LinearProgress;
