export default function copyToClipboard(
  text: string,
  onChange?: (value: boolean) => void 
) {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text).then(
      () => {
        if (onChange) onChange(true); 
      },
      () => {
        if (onChange) onChange(false);
      }
    );
  } else {
    alert(text);
    if (onChange) onChange(false); 
  }
}
