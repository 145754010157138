
// Function to handle Coinweb Stable Tokens Issued
export const formatTokenAmount = (token: number): string => {
    const tokenStr = String(token);
    if (tokenStr.length > 6) {
      const tokenStr_1 = tokenStr.slice(0, tokenStr.length - 6);
      const tokenStr_2 = tokenStr.slice(-6, -5);
      return tokenStr_2 !== "0"
        ? "$ " + tokenStr_1 + "." + tokenStr_2 + " M"
        : "$ " + tokenStr_1 + " M";
    } else if (tokenStr.length === 6) {
      return "$ 0." + tokenStr.slice(-6, -5) + " M";
    } else return "$ " + token.toLocaleString();
  };
  