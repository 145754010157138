import {CalculateTime} from "utils/types"
import moment from "moment-timezone";

export default function calculateTime(
    time: number,
    currDate: number = Date.now()
  ): CalculateTime {
    // 1. Convert timestamp to UTC moment
    const utcMoment = moment.unix(time).utc();
  
    // 2. Get user's local timezone (if available)
    const userTimezone = moment.tz.guess(); // Attempt to infer user's timezone
  
    // 3. Handle cases where user's timezone is unavailable
    const displayTimezone = userTimezone ? userTimezone : "Europe/Berlin"; // Default to CET (assuming Konstanz)
  
    // 4. Convert UTC moment to user's local time (or default)
    const localMoment = utcMoment.clone().tz(displayTimezone);
  
    // 5. Format for display (considering potential for midnight crossing)
    const display = localMoment.format("YYYY-MM-DD HH:mm:ss");
    const isMidnightCrossing =
      localMoment.format("H") === "00" && utcMoment.format("H") === "23"; // Check for midnight crossing
  
    // 6. Calculate difference from current time, handling potential crossing
    const now = moment(currDate);
    const difference = moment.duration(now.diff(localMoment));
    const h = Math.floor(difference.asHours());
    const m = Math.floor(difference.minutes());
    const s = difference.seconds();
  
    // 7. Adjust hours if there was a midnight crossing (to avoid displaying "23h")
    const adjustedHours = isMidnightCrossing ? h + 24 : h;
  
    return {
      display,
      h: adjustedHours,
      m,
      s,
    };
  }