export default function jsonParseRawContent(data: string) {
  try {
    const parsedJson = JSON.parse(data);

    if (!Array.isArray(parsedJson)) {
      throw new Error("Parsed data is not an array");
    }

    return parsedJson.map((jsonString: string) => {
      try {
        /* eslint-disable no-useless-escape */
        const trimmedJsonString = jsonString.replace(/^\"|\"$/g, "");
        return JSON.parse(trimmedJsonString) ?? trimmedJsonString;
      } catch (innerError) {
        return [];
      }
    });
  } catch (error) {
    return [];
  }
}
