/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import copyToClipboard from "utils/copyToClipboard";
//@ts-ignore
import {
  Svg,
  //@ts-ignore
  ITreeUml,
  //@ts-ignore
  IplanumlSvg,
} from "uml_f81307e07a11f1d78c0c8cf2cab553c30253b623c6a927a6d30ede7d07cc815b";
//@ts-ignore
import DebugPrint from "./DebugPrint";
import styles from "./ClaimData.module.css";

const ClaimData = (txTree: ITreeUml, planumlSvg: IplanumlSvg[]) => {
  const [treeData, setTreeDate] = useState(undefined);

  const [deep, setDeep] = useState(4);
  const [load, setLoad] = useState(true);
  const [txTreeInfo, setTxTreeInfo] = useState({});
  const [planumlSvgSave, setPlanumlSvgSave] = useState(planumlSvg);

  const [jsonTx, setJsonTx] = useState({
    set: {
      searched_txid: "",
      show_searched_tx: true,
      monochrome: false,
      show_arrow_back: true,
      show_barshadow: true,
      "3d_all": false,
      "3d_notes_only": true,
      underline_hyperlinks: true,
      hide_footbox: true,
      color_gradient: true,
    },
  });

  //@ts-ignore
  function returnAnchors(txInfo) {
    if ("treeArgs" in txInfo && txInfo.treeArgs) {
      // setIMageUrl(txInfo.newUml.svgUrl);
      // setDoneData(txInfo.newUml.umlString);
      setJsonTx({ ...txInfo.treeArgs });
      // setJsonTxDate(txInfo.treeArgs);
      // setPlantumUrl(txInfo.newUml.planumlUrl);
    }
    if ("newMas" in txInfo && txInfo.newMas) {
      setTreeDate(txInfo.newMas.data);
      setDeep(txInfo.newMas.deep);
    }
  }

  //@ts-ignore
  function updatePlanumlSvg(newPlanumlSvg) {
    setPlanumlSvgSave(newPlanumlSvg);
  }

  useEffect(() => {
    if (txTree?.txTree?.tree) {
      if (JSON.stringify(txTreeInfo) !== JSON.stringify(txTree?.txTree?.tree))
        setTxTreeInfo(txTree.txTree?.tree);
    }
    if (txTree?.txTree?.find) {
      setJsonTx({
        ...jsonTx,
        set: {
          ...jsonTx?.set,
          searched_txid: `${txTree?.txTree.find.slice(
            0,
            4
          )}..${txTree?.txTree.find.slice(-4)}`,
        },
      });
    }
  }, [txTree]);

  return (
    <>
      {load && <CircularProgress />}
      <div
        style={{
          visibility: load ? "hidden" : "visible",
          backgroundColor: "#ffffff",
          marginRight: "30px",
        }}
      >
        <Svg
          //@ts-ignore
          treeArgs={jsonTx}
          txTree={txTreeInfo}
          setLoad={setLoad}
          planumlSvg={planumlSvgSave}
          // eslint-disable-next-line
          updatePlanumlSvg={updatePlanumlSvg}
          // eslint-disable-next-line
          returnAnchors={returnAnchors}
          jarPath="plantuml-core/"
        />
      </div>
      <div>
        {treeData && (
          <>
            <button
              onClick={() => {
                if (deep > 0) setDeep(deep - 1);
              }}
            >
              - deep
            </button>
            <button onClick={() => setDeep(deep + 1)}>+ deep</button>
            <button
              onClick={() => {
                copyToClipboard( JSON.stringify(treeData) ?? '') 
              }}
            >
              Copy Json
            </button>
            <div style={{ color: "white" }}>
              <DebugPrint data={treeData} deep={deep} />
            </div>
          </>
        )}
        {txTree?.AtxTree?.tree && (
          <div style={{ color: "white" }}>
            <pre className={styles.myPre}>
              {txTree?.txTree.tree} {/* {handleJsonData()} */}
            </pre>
          </div>
        )}
      </div>
    </>
  );
};

export default ClaimData;
