import { BlockChain } from "components/TableContainer/CustomizedMui";
import { NetworkName } from "@coinweb/claims-client";

export const findSelectedNetworkClaims = (
  blockchain: BlockChain,
  claims: any | undefined
): any[] | null => {
  if (claims && blockchain) {
    const keyClaims = Object.keys(NetworkName).find(
      (key) =>
        NetworkName[key as keyof typeof NetworkName] === blockchain.fetchNetwork
    );

    if (keyClaims) {
      return claims[keyClaims].claims?.data || []; 
    }
  }
  return null; 
};
