import Typography from "@mui/material/Typography";
import CwebIcon from "../../assets/Icons/Networks/cweb.png";

type CardTitleProps = {
  title: string;
};

const CardTitle = (props: CardTitleProps) => {
  const { title } = props;

  return (
    <div style={{ textAlignLast: "center" }}>
      <img id="Block-icon" src={CwebIcon} alt="bc" />
      <Typography className="BoldBlueFont18">{title}</Typography>
    </div>
  );
};
export default CardTitle;
