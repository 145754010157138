import React from "react";
import { Modal, Fade, Backdrop, Typography, Tooltip } from "@mui/material";
import moment from "moment-timezone";
import { formatKey } from "utils/formatKey";
import { isHexHash } from "utils/isHexHash";
import handleTxIds from "utils/handleTxIds";
import CopyIcon from "components/CopyIcon";
import CloseIcon from "@mui/icons-material/Close";
import convertHextoMinUnits from "utils/convertHextoMinUnits";
import CardTitle from "./CardTitle";

interface TableRowModalProps {
  open: boolean;
  onClose: () => void;
  data: any;
}
const isTimestamp = (value: number) => {
  const asSeconds = moment.unix(value).isValid();
  const asMilliseconds = moment(value).isValid();
  return asSeconds || asMilliseconds;
};

const formatTimestamp = (value: number) => {
  const isMilliseconds = String(value).length > 10;
  const formattedTime = isMilliseconds
    ? moment(value).format("YYYY-MM-DD")
    : moment.unix(value).format("YYYY-MM-DD");
  return formattedTime;
};

export const RenderParameters = ({ data }: { data: any }) => {

  if (!data || typeof data !== "object") {
    return null;
  }

  const isHexNumber = (value: string) => {
    // Check if it's hex, not a txid, and doesn't exceed typical number lengths
    return /^0x[0-9a-fA-F]+$/.test(value) && value.length <= 66; 
  };

  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        if (value === null) {
          return (
            <div key={key} className="grid_cell">
              <Typography className="BoldFont16" align="left">
                {formatKey(key)}
              </Typography>
              <div className="L2TxId-row">
                <Typography
                  className="BoldFont14"
                  align="center"
                  sx={{
                    marginBottom: "-5px !important",
                    overflowWrap: "anywhere",
                  }}
                >
                  null
                </Typography>
              </div>
            </div>
          );
        }

        if (typeof value === "object" && !Array.isArray(value)) {
          return (
            <div
              key={key}
              className="grid_cell"
              style={{
                border: "solid 1px #fff",
                borderRadius: "6px",
                padding: "5px",
              }}
            >
              <Typography
                className="BoldBlueFont16"
                align="left"
                sx={{
                  marginBottom: "-5px !important",
                  overflowWrap: "anywhere",
                }}
              >
                {formatKey(key)}
              </Typography>
              <RenderParameters data={value} />
            </div>
          );
        } else {
          return (
            <div key={key} className="grid_cell">
              <Typography className="BoldFont16" align="left">
                {formatKey(key)}
              </Typography>
              <div className="L2TxId-row">
                <Typography
                  className="BoldFont14"
                  align="center"
                  sx={{
                    marginBottom: "-5px !important",
                    overflowWrap: "anywhere",
                  }}
                >
                  {Array.isArray(value) ? (
                    value.join(", ")
                  ) : isHexNumber(String(value)) ? (
                    <Typography className="BoldFont14">
                      {convertHextoMinUnits(String(value))}{" "}
                    </Typography>
                  ) : typeof value === "number" && isTimestamp(value) ? (
                    // Case: It's a timestamp
                    formatTimestamp(value)
                  ) : isHexHash(String(value)) ? (
                    // Case: It's a transaction ID (hex)
                    <div className="L2TxId-row">
                      <Tooltip
                        componentsProps={{
                          tooltip: {
                            sx: {
                              width: "min-content !important",
                              backgroundColor: "#4f4f72 !important",
                              padding: "0px !important",
                              marginTop: "-1px !important",
                            },
                          },
                        }}
                        disableFocusListener
                        TransitionComponent={Fade}
                        title={<div id="TxId-tooltip">{String(value)}</div>}
                      >
                        <div>
                          <Typography
                            className="BoldFont14"
                            align="center"
                            sx={{ marginLeft: "3px" }}
                          >
                            {handleTxIds(String(value))}{" "}
                          </Typography>
                        </div>
                      </Tooltip>
                      <CopyIcon data={String(value)} />
                    </div>
                  ) : (
                    // Case: Default string output
                    String(value)
                  )}
                </Typography>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};

const TableRowModal: React.FC<TableRowModalProps> = ({
  open,
  onClose,
  data,
}) => {

  return (
    <Modal
     aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={() => onClose()}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Fade in={open}>
        <div
          style={{
            padding: "20px",
            borderRadius: "6px",
            margin: "20px",
            width: "fit-content",
            minHeight: "50vh",
            backgroundImage:
              "linear-gradient(to bottom, #353763, #272852) !important",
            border: "none",
            zIndex: 1301,
            overflow: "scroll",
            backgroundColor: "#2d2e57",

          }}
        >
          {" "}
          <div
            style={{ width: "100%", display: "flex", justifyContent: "right" }}
          >
            <CloseIcon
              sx={{ color: "#fff", cursor: "pointer" }}
              onClick={(e) => {
                e.stopPropagation();
                onClose();
              }}
            />
          </div>
          <CardTitle title="Claim Body in detail" />
          <div className="grid_container">
            <RenderParameters data={data} />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default TableRowModal;
