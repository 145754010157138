import React from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import { defaultClient } from "./graphqlClients";
import { BrowserRouter } from "react-router-dom";
import "./fonts/cheyenne-sans/CheyenneSans-Bold.ttf";
import "./fonts/cheyenne-sans/CheyenneSans-Medium.ttf";
import "./fonts/cheyenne-sans/CheyenneSans-Regular.ttf";
import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient();
ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ApolloProvider client={defaultClient}>
      <React.StrictMode>
        <BrowserRouter>
          <App />
          <ToastContainer />
        </BrowserRouter>
      </React.StrictMode>
    </ApolloProvider>
  </QueryClientProvider>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
