import FormControl, { FormControlProps } from "@mui/material/FormControl";
import Divider, { DividerProps } from "@mui/material/Divider";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import Paper, { PaperProps } from "@mui/material/Paper";
import Button, { ButtonProps } from "@mui/material/Button";
import { createSvgIcon } from "@mui/material/utils";
import { styled } from "@mui/material/styles";
import Card, { CardProps } from "@mui/material/Card";
import CardContent, { CardContentProps } from "@mui/material/CardContent";
import { BlockChains } from "utils/constants";
import { NetworkName } from "../../generated/graphql";
import { NetworkName as devNetworkName } from "../../generated/graphql-devnet";
import { REACT_APP_ENABLED_NETWORKS } from "conf";
import {
  NetworkName as FetchNetwork 
} from "@coinweb/claims-client";
import BlockError from "../../assets/Icons/block-error.svg";

export const MOBILE_WIDTH = 450;

//**** TABLE CONTAINER COMPONENTS */
export type BlockChainTicker =
  | "BTC"
  | "BCH"
  | "tBCH"
  | "tBTC"
  | "ETH"
  | "tETH"
  | "LTC"
  | "tLTC"
  | "EGLD"
  | "tEGLD"
  | "BNB"
  | "tBNB"
  | "MATIC"
  | "tMATIC"
  | "DOGE"
  | "tDOGE"
  | "KUJI"
  | "tKUJI"
  | "ARB"
  | "tARB"
  | "devnetA"
  | "devnetB";

export type BlockChain = {
  networkName: NetworkName | devNetworkName;
  label: BlockChainTicker;
  hover: string;
  currency: string;
  icon: string;
  fetchNetwork: FetchNetwork;
  searchQueries:string[]
};

export const BlockChainArray: BlockChain[] = REACT_APP_ENABLED_NETWORKS.map(
  (n) => BlockChains[n]
);

//Icon handlind for select s *** dropdown icon *** in mobile screens
export const SelectIcon = createSvgIcon(
  <path
    d="M16.631 0 9 6.367 1.369 0 0 1.491 9 9l9-7.509z"
    fill="#D3D3EF"
    fillRule="evenodd"
  />,
  "Select"
);
/**  MUI Connect Metamask Button Styling  **/
export const MetamaskButton = styled(Button)<ButtonProps>(() => ({
  borderadius: "6px!important",
  backgroundImage: "linear-gradient(to bottom, #aeaed1, #626089) !important",
  fontSize: "12px!important",
  boxShadow: "none !important",
  height: "44px!important",
  width: "100% !important",
  fontFamily: ' "DINosaur Med", "CheyenneMed", sans-serif!important',
  fontWeight: 700,
  fontStretch: "normal!important",
  fontStyle: "normal!important",
  lineHeight: "0.86!important",
  letterSpacing: "1.17px!important",
  color: "#fff!important",
  maxWidth: "175px !important",
}));
/**MUI Table Container Search Paper Styling**/
export const SearchPaper = styled(Paper)<PaperProps>(() => ({
  borderRadius: "20px",
  display: "flex",
  alignItems: "center",
  minWidth: 175,
  backgroundColor: "transparent",
  border: "solid 1px #4f4f72",
  height: "40px",
  boxShadow: "none",
}));

/**MUI Table Container Search InputBase Styling**/
export const SearchInputBase = styled(InputBase)<InputBaseProps>(() => ({
  ml: 1,
  flex: 1,
  fontSize: "14px",
  color: "#d3d3ef!important",
  fontFamily: '"DINosaur Med", "CheyenneMed"',
  fontWeight: 500,
  textAlign: "right",
  minWidth: "174px",
  height: "100%",
  textOverflow: "ellipsis",
  marginRight: "10px",
  /**MUI Table Container Search InputBase inner input Styling for development**/
  ".MuiInputBase-input.css-yz9k0d-MuiInputBase-input": {
    color: "#d3d3ef !important",
    textIndent: "10px!important",
    /**MUI Table Container Search InputBase inner input placeholder Styling for development  **/
    "&::-webkit-input-placeholder": {
      opacity: "1!important",
    },
    /**MUI Table Container Search InputBase inner input placeholder Styling for development  **/
    "&::-moz-placeholder": {
      opacity: "1!important",
    },
    /**MUI Table Container Search InputBase inner input placeholder Styling for development  **/
    "&::-ms-input-placeholder": {
      opacity: "1!important",
    },
  },
  /**MUI Table Container Search InputBase inner input Styling for build**/
  ".MuiInputBase-input.css-mnn31": {
    textIndent: "10px!important",
    /**MUI Table Container Search InputBase inner input placeholder Styling for build**/
    "&::-webkit-input-placeholder": {
      color: "#d3d3ef!important",
      opacity: "1!important",
    },
    /**MUI Table Container Search InputBase inner input placeholder Styling for build**/
    "&::-moz-placeholder": {
      color: "#d3d3ef!important",
      opacity: "1!important",
    },
    /**MUI Table Container Search InputBase inner input placeholder Styling for build**/
    "&::-ms-input-placeholder": {
      color: "#d3d3ef!important",
      opacity: "1!important",
    },
  },
}));

export const TabFormControl = styled(FormControl)<FormControlProps>(() => ({
  m: 1,
  margin: "0px !important",
  width: "100% !important",
  minWidth: "120px",
  height: "44px !important",
  background: "#37385f",
  borderRadius: "6px",
  ".MuiOutlinedInput-root": {
    height: "44px !important",
  },
  /** MUI Table Container Form Control Select icon Styling for development **/
  ".css-hfutr2-MuiSvgIcon-root-MuiSelect-icon": {
    top: "18px !important",
  },
  /** MUI Table Container Form Control Select icon Styling for development **/
  ".css-1636szt": {
    top: "18px !important",
  },
}));

/**  MUI Table Container Menu Divider Styling for mobile screen **/
export const TabMenuDivider = styled(Divider)<DividerProps>(() => ({
  borderStyle: "solid",
  borderColor: "#4f4f72 !important",
  borderBottomWidth: "thin",
  marginLeft: "5px !important",
  marginRight: "5px !important",
}));

/**  MUI Table Container Divider Styling for mobile screen  **/
export const ContainerDivider = styled(Divider)<DividerProps>(() => ({
  height: "3px!important",
  backgroundColor: "#4f4f72 !important",
  margin: "20px 0px 22px 0px !important",
}));
export const L2TxLogMuiCard = styled(Card)<CardProps>(() => ({
  height: "100%",
  boxShadow: "none",
  backgroundImage: "linear-gradient(to bottom, #353763, #272852) !important",
  borderRadius: "6px",
  textIndent: "0px",
  backgroundColor: "transparent",
}));

/**  MUI L2TxLogCard CardContent Styling  **/
export const L2TxLogMuiCardContent = styled(CardContent)<CardContentProps>(
  () => ({
    padding: "20px 20px !important",
    height: "100%",
  })
);

/**  MUI L2TxLogCard Divider Styling  **/
export const L2TxLogDivider = styled(Divider)<DividerProps>(() => ({
  backgroundColor: "#4f4f72 !important",
  height: "1px !important",
  borderBottomWidth: "0px !important",
  width: "100% !important",
  marginLeft: "0px !important",
  marginRight: "0px !important",
  marginTop: "20px !important",
  marginBottom: "20px !important",
}));

export const DataNotFoundSection = (
  <div id="Error-container">
    <div id="Error-icon-container">
      <img src={BlockError} alt="not_found"></img>
    </div>
    <div id="Error-message-container">
      <div className="Error-text">DATA NOT FOUND</div>
    </div>
  </div>
);