import { List, ListItem, Box, Typography } from "@mui/material";
import { Suggestions } from "utils/types";
import "./SearchSuggestions.css";
import { useSearchParams } from "react-router-dom";

interface SearchSuggestionsProps {
  suggestions: Suggestions[]; // The correct type definition for the suggestions prop
  onSuggestionClick: (query: string) => void; // Callback function for suggestion click
  onDisplayChange: (val: boolean) => void;
}

const SearchSuggestions: React.FC<SearchSuggestionsProps> = ({
  suggestions,
  onSuggestionClick,
  onDisplayChange,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div id="container">
      <Box id="gradient_box">
        <List>
          {suggestions.map((suggestion, index) => (
            <ListItem
              key={index}
              id="list_item"
              onClick={() => {
                onSuggestionClick(suggestion.data);
                onDisplayChange(false);
                const newSearchParams = new URLSearchParams(
                  searchParams.toString()
                );
                newSearchParams.set("search", suggestion.data);
                setSearchParams(newSearchParams);
              }}
            >
              <Typography>
                {suggestion.type === "Contract"
                  ? `Contract ${
                      suggestion.dataType === "alias" ? " Alias: " : "Txid: "
                    } ${suggestion.data}`
                  : suggestion.type === "ContractInstance"
                  ? `Contract Instance ${
                      suggestion.dataType === "alias" ? " Alias: " : "Txid: "
                    } ${suggestion.data}`
                  : `Interpreter ${
                      suggestion.dataType === "alias" ? " Alias: " : "Txid: "
                    } ${suggestion.data}`}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
};

export default SearchSuggestions;
