import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Redirect() {
  const navigate = useNavigate();
  useEffect(() => {
    let txInfo = window.location.pathname.split('/')
    navigate(`/?hash=${txInfo[txInfo.length - 1]}`)
  }, [navigate]);

  return <></>;
}

export default Redirect;
