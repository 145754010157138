export default function formatTime(
    hour: number,
    minute: number,
    second: number
  ): string {
    // 1. Calculate total time in seconds
    const totalSeconds = hour * 3600 + minute * 60 + second;
  
    // 2. Handle edge case for "just now"
    if (totalSeconds === 0 || totalSeconds === 1 || totalSeconds === 2) {
      return "now";
    }
  
    // 3. Extract individual time components (avoiding unnecessary calculations)
    const days = Math.floor(totalSeconds / 86400); // 86400 = seconds in a day
    const remainingSeconds = totalSeconds % 86400;
    const hours = Math.floor(remainingSeconds / 3600);
    const minutes = Math.floor((remainingSeconds % 3600) / 60);
    const seconds = remainingSeconds % 60;
  
    // 4. Build the time string (consider pluralization)
    const parts = [];
    if (days > 0) {
      parts.push(`${days} day${days > 1 ? "s" : ""}`);
    }
    if (hours > 0) {
      parts.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    }
    if (minutes > 0) {
      parts.push(`${minutes} minute${minutes > 1 ? "s" : ""}`);
    }
    if (
      seconds > 0 &&
      (parts.length === 0 || !parts[parts.length - 1].includes("second"))
    ) {
      // Only add seconds if no other time units or if seconds haven't been added already
      parts.push(`${seconds} second${seconds > 1 ? "s" : ""}`);
    }
  
    // 5. Join parts and add "ago"
    return parts.join(" ") + " ago";
  }
  