// import { FooterButton, FooterInput, Icons } from "./CustomizedMui";
import { Icons } from "./CustomizedMui";

import "./styles.css";
// import SuccessModal from "./SuccessModal";
// import { useState, useEffect } from "react";
// import { sendEmail, getArticleData } from "./api";
// import { REACT_APP_SUBSCRIPTION_INPUT_ENDPOINT } from "../../conf";
type FooterProps = {
  width: number;
};

// const ariaLabel = { "aria-label": "description" };

const Footer = (props: FooterProps) => {
  const { width } = props;
  // const [contactName, setContactName] = useState("");
  // const [contactEmail, setContactEmail] = useState("");
  // const [contactMessage, setContactMessage] = useState("");
  // const [trendTitle, setTrendTitle] = useState("");
  // const [trendDate, setTrendDate] = useState("");
  // const [trendImgUrl, setTrendImgUrl] = useState("");
  // const [articleLink, setArticleLink] = useState("");
  // const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  // useEffect(() => {
  //   getArticleData(setTrendImgUrl, setTrendDate, setArticleLink, setTrendTitle);
  // }, []);

  // const handleSendEmail = async (e: any) => {
  //   e.preventDefault();
  //   await sendEmail(contactName, contactEmail, contactMessage);
  //   setIsSuccessModalOpen(true);

  //   setContactName("");
  //   setContactEmail("");
  //   setContactMessage("");
  // };
  // const handleCloseSuccessModal = () => {
  //   setIsSuccessModalOpen(false);
  // };
  return (
    <div id="Footer-outer-container">
      <div id="Footer-container">
        <div data-aos="fade-up" id="Footer-icon-section">
          <span id="Footer-text">Follow us</span>
          <div id="Footer-icon-wrapper">
            <ul id="Footer-icon-ul">
              {width > 1024
                ? Icons.map((icon, index) => (
                    <li
                      key={icon.alt}
                      className=".Footer-icon-li"
                      style={{
                        marginRight:
                          index + 1 === Icons.length ? "0px" : "35px",
                      }}
                    >
                      <a className="Footer-a" href={icon.link}>
                        <img
                          className="Footer-icon-img"
                          src={icon.icon}
                          alt={icon.alt}
                          style={{
                            marginLeft:
                              icon.alt === "telegram" ? "-2px" : "0px",
                          }}
                        />
                      </a>
                    </li>
                  ))
                : Icons.map((icon) => (
                    <li
                      key={icon.alt}
                      className="Footer-icon-li-mobile"
                      style={{ height: "fit-content" }}
                    >
                      <a className="Footer-a" href={icon.link}>
                        <img
                          className="Footer-icon-img"
                          src={icon.icon}
                          alt={icon.alt}
                          style={{
                            marginLeft:
                              icon.alt === "telegram" ? "-2px" : "0px",
                          }}
                        />
                      </a>
                    </li>
                  ))}
            </ul>
          </div>
        </div>
        {/* <div id="Footer-bottom-section">
          <div data-aos="fade-up" data-aos-delay="100">
            <div className="Footer-bottom-grid-header">
              Contact us
              <div className="stripe-right"></div>
            </div>
            <form id="footer_form" onSubmit={(e) => handleSendEmail(e)}>
              <div id="Footer-bottom-section-contact-form-upper">
                <FooterInput
                  value={contactName}
                  onChange={(e) => setContactName(e.target.value)}
                  name="user_name"
                  placeholder="Your name"
                  inputProps={ariaLabel}
                />
                <FooterInput
                  value={contactEmail}
                  onChange={(e) => setContactEmail(e.target.value)}
                  name="user_email"
                  placeholder="Your email"
                  inputProps={ariaLabel}
                />
              </div>
              <div id="Footer-bottom-section-contact-form-below">
                <FooterInput
                  value={contactMessage}
                  onChange={(e) => setContactMessage(e.target.value)}
                  name="message"
                  multiline
                  rows={3}
                  sx={{ height: "auto !important", marginBottom: "20px" }}
                  placeholder="Type your comment here"
                  inputProps={ariaLabel}
                />
                <div id="Button-footer-container">
                  <FooterButton
                    type="submit"
                    id="Button-footer"
                    variant="contained"
                  >
                    SEND
                  </FooterButton>
                </div>
              </div>
            </form>
          </div>
          <SuccessModal
            open={isSuccessModalOpen}
            onClose={handleCloseSuccessModal}
          />

          <div
            data-aos="fade-up"
            data-aos-delay="200"
            id="Footer-bottom-section-trend"
          >
            <div className="Footer-bottom-grid-header">
              Trending post
              <div className="stripe-right"></div>
            </div>
            <div id="Footer-img-container">
              <a href={articleLink}>
                <img id="Footer-img" src={trendImgUrl} alt="trend-img"></img>
              </a>
            </div>
            <div id="Footer-trend-text-container">
              <div id="Footer-trend-text-header">{trendTitle}</div>
              <div id="Footer-trend-text-date">{trendDate}</div>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-delay="300">
            <div className="Footer-bottom-grid-header">
              Sign up to learn more
              <div className="stripe-right"></div>
            </div>
            <iframe
              src={REACT_APP_SUBSCRIPTION_INPUT_ENDPOINT}
              data-test-id="beehiiv-embed"
              height="52"
              frameBorder="0"
              scrolling="no"
              style={{
                width: "100%",
                margin: 0,
                borderRadius: "0px !important",
                backgroundColor: "transparent",
              }}
              title="subscription_input"
            ></iframe>
            <p id="Footer-bottom-signup-text">
              Subscribe and be the first to hear about updates, launches, and
              opportunities.
            </p>
          </div>
          <div className="stripe-left"></div>
        </div> */}
        <div id="Footer-bottom-text">
          © {new Date().getFullYear()} Coinweb — Cross-Chain Computation Platform
        </div>
      </div>
    </div>
  );
};

export default Footer;
