import { gql as GQL } from "graphql-request";

export const QUERY_L2_TX_FULL_LOG_GQL = GQL`
query L2TxFullLog($txid: String!, $network: NetworkName!) {
  l2TxFullLog(txid: $txid, network: $network) {
    ...FullTxLog 
    }   
  }
     
  fragment TxLoaded on TxLoadedGql {
    __typename
    content
    parents {
       ...Contract
       ...EmbeddedL1Tx
    }
  }
 
  fragment Contract on ContractGql {
    __typename
    contract
    parents {
       ...L2Parent
    }
  }
 
  fragment EmbeddedL1Tx on EmbeddedGql {
    __typename
    l1Txs
  }
 
  fragment ContactExecuted on ContactExecutedGql {
    __typename
    contract
    children
    op
    jumpChildren{
      l2Txid
      toShard
    }
  }
 
  fragment LegacyCwebTxLoaded on LegacyCwebTxLoadedGql {
    __typename
    amount
    l1Txid
    content
  }
 
  fragment TxLogError on TxLogErrorGql {
    __typename
    error
  }
 
 
  
  fragment GqlClaim on GqlClaim {
    __typename
    key
    body
    feesStored
  }  

  fragment L2Parent on ParentGql {
    __typename
     l2Txid
     opPosition
  }
  
  fragment FullTxLog on FullTxLogGql {
    network
    txid
    execStep
    blockHeight
    txIssuer
    rawContent
    txLogInfo {
      ...TxLoaded
      ...ContactExecuted
      ...TxLogError
      ...LegacyCwebTxLoaded
    
      ...GqlClaim
    }
  }
`;
