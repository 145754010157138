import { gql } from "@apollo/client";
import { gql as GQL } from "graphql-request";

// Subsections are split into fragments
// to make `codegen` generate more types
export const BLOCK_FRAGMENT = gql`
  fragment Block on Block {
    hash
    height
    time
    amountTransacted
    size
    numberOfTransactions
    network
    tx {
      ...Tx
    }
    l2Tx {
      ...L2Tx
    }
  }
  fragment L2Tx on CwebTransactionGQL {
    rawContent
    txid
    fees
    amountTransacted
  }
  fragment Tx on Transaction {
    txid
    amountTransacted
    network
  }
`;

export const QUERY_BLOCK = gql`
  query Block($hash: String!, $network: NetworkName!) {
    block(hash: $hash, network: $network) {
      ...Block
    }
    ${BLOCK_FRAGMENT}
  }
`;

export const QUERY_BLOCK_BY_HEIGHT = gql`
  query BlockByHeight($height: Int!, $network: NetworkName!) {
    blockByHeight(height: $height, network: $network) {
      ...Block
    }
    ${BLOCK_FRAGMENT}
  }
`;

export const QUERY_BLOCK_GQL = GQL`
  query Block($hash: String!, $network: NetworkName!) {
    block(hash: $hash, network: $network) {
      ...Block
    }
  }
  fragment Block on Block {
    hash
    height
    time
    amountTransacted
    size
    numberOfTransactions
    network
    tx
    {
      ...Tx
    }
    l2Tx
    {
      ...L2Tx
    }
  }
  fragment L2Tx on CwebTransactionGQL {
      rawContent
      txid
      fees
      amountTransacted
  }
  fragment Tx on Transaction {
      txid
      amountTransacted
      network
  }
`;

export const QUERY_BLOCK_BY_HEIGHT_GQL = GQL`
  query BlockByHeight($height: Int!, $network: NetworkName!) {
    blockByHeight(height: $height, network: $network) {
      ...Block
    }
  }
  fragment Block on Block {
    hash
    height
    time
    amountTransacted
    size
    numberOfTransactions
    network
    tx
    {
      ...Tx
    }
    l2Tx
    {
      ...L2Tx
    }
  }
  fragment L2Tx on CwebTransactionGQL {
      rawContent
      txid
      fees
      amountTransacted
  }
  fragment Tx on Transaction {
      txid
      amountTransacted
      network
  }
`;
