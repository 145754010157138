import { memo, useState } from "react";
import copyToClipboard from "utils/copyToClipboard";
import IconButton from "@mui/material/IconButton";
import { Fade, Tooltip } from "@mui/material";
import BlockHashIcon from "assets/Icons/block-hash.svg";
import BlueBlockHashIcon from "assets/Icons/blue-block-hash.svg";

type CopyIconProps = {
  data: string;
};

const CopyIcon = (props: CopyIconProps) => {
  const { data } = props;

  const [txIdCopy, setTxIdCopy] = useState(false);
  const [BlockIcon, setBlockIcon] = useState(false);

  const handleLeave = () => {
    setBlockIcon(false);
    setTxIdCopy(false);
  };
  return (
    <>
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "#4f4f72 !important",
              padding: "0px !important",
              marginTop: "-1px !important",
            },
          },
        }}
        disableFocusListener
        TransitionComponent={Fade}
        className="Tooltip-hash-container"
        placement="bottom"
        title={<div id="TxId-tooltip">{txIdCopy ? "Copied" : data}</div>}
      >
        <IconButton
          onClick={() => {
            copyToClipboard(data, setTxIdCopy);
          }}
          onMouseEnter={() => setBlockIcon(true)}
          onMouseLeave={handleLeave}
          sx={{ p: "0px 0px 0px 10px" }}
          aria-label="row"
        >
          <img
            className="Blockhash-icon"
            src={BlockIcon ? BlueBlockHashIcon : BlockHashIcon}
            alt={"Block hash"}
          ></img>
        </IconButton>
      </Tooltip>
    </>
  );
};

export default memo(CopyIcon);
