import { useState, useEffect } from "react";
import pako from "pako"; // Assuming pako is imported for gzip decompression
import tar from "tar-stream"; // Assuming tar-stream is imported for tar extraction

// Define the type for the file object
interface FileObject {
  [key: string]: Uint8Array;
}

// Custom hook
export const useExtractFilesFromUrl = (packageUrl?: string) => {
  const [files, setFiles] = useState<FileObject | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!packageUrl) {
      setLoading(false);
      return;
    }

    const fetchAndExtractFile = async () => {
      try {
        setLoading(true);
        const response = await fetch(packageUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Convert the response to an ArrayBuffer
        const arrayBuffer = await response.arrayBuffer();

        // Decompress the .tgz file
        const decompressed = pako.ungzip(new Uint8Array(arrayBuffer));

        // Create an extraction stream
        const extract = tar.extract();
        const files: FileObject = {};

        extract.on("entry", (header: any, stream: any, next: any) => {
          let fileData: Uint8Array[] = [];

          stream.on("data", (chunk: Uint8Array) => {
            fileData.push(chunk);
          });

          stream.on("end", () => {
            const uint8Array = new Uint8Array(
              fileData.reduce<number[]>(
                (acc, val) => acc.concat(Array.from(val)),
                []
              )
            );
            files[header.name] = uint8Array;
            next(); // Ready for next entry
          });

          stream.resume();
        });

        extract.on("finish", () => {
          setFiles(files);
          setLoading(false);
        });

        // Pass the decompressed data to the tar extractor
        extract.end(decompressed);
      } catch (error) {
        setError(error as Error);
        setLoading(false);
      }
    };

    fetchAndExtractFile();
  }, [packageUrl]);

  return { files, error, loading };
};
