export default function seperateDataKeys(data: any) {
  return data.reduce((acc: any, obj: any) => {
    const key = Object.keys(obj)[0]; // Get the first key in the object
    if (!acc[key]) {
      acc[key] = []; // Initialize an array for the key if it doesn't exist
    }
    acc[key].push(obj[key]); // Push the object to the array for the corresponding key
    return acc;
  }, {});
}
