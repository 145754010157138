import { Suggestions } from "utils/types";
export default function convertInterpretersToSuggestions(interpreters: {
  [key: string]: any;
}): Suggestions[] {
  const suggestions: Suggestions[] = [];

  for (const txid in interpreters) {
    const interpreter = interpreters[txid];
    suggestions.push({
      data: interpreter.alias,
      dataType: "alias",
      type: "Interpreter",
    });

    suggestions.push({
      data: txid,
      dataType: "tx",
      type: "Interpreter",
    });
  }

  return suggestions;
}
