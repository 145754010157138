import { Suggestions } from "utils/types";

export default function convertContractInstancesToSuggestions(contracts: {
  [key: string]: any;
}): Suggestions[] {
  const suggestions: Suggestions[] = [];

  for (const alias in contracts) {
    const contract = contracts[alias];

    suggestions.push({
      data: contract.alias,
      dataType: "alias",
      type: "ContractInstance",
    });

    suggestions.push({
      data: contract?.instance_id || "",
      dataType: "tx",
      type: "ContractInstance",
    });
  }

  return suggestions;
}
