import React from "react";

import { REACT_APP_COINWEB_STABLE_TOKEN_ISSUED } from "conf";
import { formatTokenAmount } from "utils/formatTokenAmount";
import Counter from "../Counter/Counter";

const displayCoinwebStableTokenIssued =
    REACT_APP_COINWEB_STABLE_TOKEN_ISSUED === "true";

type StatisticsProperties = {
  cwTxs: number,
  l1Txs: number,
  cwTxVol: number,
  networkNums: number,
}

export const Statistics = ({cwTxs, l1Txs, cwTxVol, networkNums}: StatisticsProperties) => (
  <React.Fragment>
    <Counter
      label={"Coinweb Transactions"}
      statistics={cwTxs.toLocaleString()}
    />
    <Counter
      label={"Layer 1 Transactions"}
      statistics={l1Txs.toLocaleString()}
    />
    {displayCoinwebStableTokenIssued && (
      <Counter
        label={"Coinweb Stable Tokens Issued"}
        statistics={formatTokenAmount(cwTxVol)}
      />
    )}

    <Counter label={"Available Networks"} statistics={String(networkNums)} />
  </React.Fragment>
);