import PropTypes from "prop-types";
import VueJsonPretty from "vue-json-pretty";
import { applyVueInReact } from "veaury";
import "vue-json-pretty/lib/styles.css";

const DebugPrint = (props) => {
  const JsonPretty = applyVueInReact(VueJsonPretty);

  return (
    <div>
      <JsonPretty data={props.data} deep={props.deep} showIcon theme="dark" />
    </div>
  );
};

DebugPrint.propTypes = {
  data: PropTypes.any,
  deep: PropTypes.any,
};

export default DebugPrint;
