import { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import iconCross from "assets/Icons/cross.svg";
import copyToClipboard from "utils/copyToClipboard";
import jsonParseRawContent from "utils/jsonParseRawContent";
import styles from "./RawContentModal.module.css";

type Props = {
  handleClose: () => void;
  open: boolean;
  data: string;
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "14px",
  width: "90%",
  height: "90%",
  boxShadow: " 0 8px 25px 0 rgba(0, 0, 0, 0.31)",
  color: "#fff",
  padding: "20px",
  backgroundColor: "#2d2e57",
};
const RawContentModal = (props: Props) => {
  const [displayData, setDisplayData] = useState("");
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    if (props.data) {
      setDisplayData(JSON.stringify(jsonParseRawContent(props.data), null, 2));
    } else setDisplayData("");
  }, [props.data]);
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box sx={style}>
          <div className={styles.header}>
            <div>
              <div className={styles.copyBox}>
                <button
                  type="button"
                  className={styles.copyButton}
                  onClick={() => {
                    copyToClipboard(displayData, setCopy);
                  }}
                >
                  {copy ? "Copied" : "Copy"}
                </button>
              </div>
            </div>
            <div onClick={props.handleClose}>
              <img style={{ cursor: "pointer" }} src={iconCross} alt="" />
            </div>
          </div>
          <div className={styles.headerBody}>
            <pre style={{ height: "100%", overflow: "scroll" }}>
              {displayData} 
            </pre>
          </div>
          <div className={styles.headersblockList}></div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default RawContentModal;
