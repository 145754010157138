import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string | number; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigInt: { input: any; output: any; }
  CwebToken: { input: any; output: any; }
  HashId: { input: any; output: any; }
  /** A scalar that can represent any JSON value. */
  JSON: { input: any; output: any; }
  /**
   * ISO 8601 calendar date without timezone.
   * Format: %Y-%m-%d
   *
   * # Examples
   *
   * * `1994-11-13`
   * * `2000-02-24`
   */
  NaiveDate: { input: any; output: any; }
  /**
   * ISO 8601 combined date and time without timezone.
   *
   * # Examples
   *
   * * `2015-07-01T08:59:60.123`,
   */
  NaiveDateTime: { input: any; output: any; }
  PubKey: { input: any; output: any; }
  TokenAmount: { input: any; output: any; }
  /**
   * A UUID is a unique 128-bit number, stored as 16 octets. UUIDs are parsed as
   * Strings within GraphQL. UUIDs are used to assign unique identifiers to
   * entities without requiring a central allocating authority.
   *
   * # References
   *
   * * [Wikipedia: Universally Unique Identifier](http://en.wikipedia.org/wiki/Universally_unique_identifier)
   * * [RFC4122: A Universally Unique IDentifier (UUID) URN Namespace](http://tools.ietf.org/html/rfc4122)
   */
  UUID: { input: any; output: any; }
};

export type BlockAnchor = {
  __typename?: 'BlockAnchor';
  hash?: Maybe<Scalars['String']['output']>;
  height: Scalars['BigInt']['output'];
};

export type BlockAnchorInput = {
  hash?: InputMaybe<Scalars['String']['input']>;
  height: Scalars['BigInt']['input'];
};

export type DataResult = {
  __typename?: 'DataResult';
  /** Hex-string of embedded data */
  data: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

/** Graphql representation of Ecdsa user information */
export type EcdsaUser = {
  __typename?: 'EcdsaUser';
  /** user public key (32 bytes hash) */
  pubKey: Scalars['PubKey']['output'];
};

export enum EmbedStatus {
  Confirmed = 'CONFIRMED',
  Mempool = 'MEMPOOL',
  Pending = 'PENDING'
}

export enum FiatName {
  Eur = 'EUR',
  Gbp = 'GBP',
  Usd = 'USD'
}

/** Custom token extra field */
export type Field = {
  __typename?: 'Field';
  content: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type GeneralTokensInfo = {
  __typename?: 'GeneralTokensInfo';
  /** List of all tokens */
  tokens: Array<Scalars['HashId']['output']>;
};

/** Graphql representation of custom token bonding curve fields */
export type GqlBondingCurveData = {
  __typename?: 'GqlBondingCurveData';
  curveBuy: GqlFormula;
  curveSell: GqlFormula;
  cwebSupply: Scalars['CwebToken']['output'];
  domain: Scalars['CwebToken']['output'];
  tokenSupply: GqlTokenSupply;
};

/** Graphql representation of bonding curve data for a custom token */
export type GqlBondingCurveInfo = {
  __typename?: 'GqlBondingCurveInfo';
  /** True if the state of the curve is fixed */
  isFrozen: Scalars['Boolean']['output'];
  /** True if the naked tokens allowed */
  isNakedTokensAllowed: Scalars['Boolean']['output'];
};

/** GQL representing CwebClaims */
export type GqlClaim = {
  __typename?: 'GqlClaim';
  body: Scalars['JSON']['output'];
  feesStored: Scalars['JSON']['output'];
  key: Scalars['JSON']['output'];
};

/** GQL representation of ClaimId */
export type GqlClaimFilter = {
  /** Ignored if `key_second_part` is set */
  endsAtKeySecondPart?: InputMaybe<Scalars['JSON']['input']>;
  issuer: Scalars['JSON']['input'];
  keyFirstPart: Scalars['JSON']['input'];
  keySecondPart?: InputMaybe<Scalars['JSON']['input']>;
  /** Ignored if `key_second_part` is set */
  startsAtKeySecondPart?: InputMaybe<Scalars['JSON']['input']>;
};

export type GqlCwebPriceHistoryInIntervals = {
  __typename?: 'GqlCwebPriceHistoryInIntervals';
  histCwebPricesInFiatInIntervals: Array<GqlNetworkPriceHistoryInIntervalsSet>;
  numberOfIntervalsRequested: Scalars['Int']['output'];
  numberOfIntervalsReturned: Scalars['Int']['output'];
  reportedInterval: HistoryInterval;
  requestedFiat: FiatName;
  requestedHistDaysBack: Scalars['Int']['output'];
  utcDatetimeNow: Scalars['NaiveDateTime']['output'];
};

export type GqlCwebUsdHistory = {
  __typename?: 'GqlCwebUsdHistory';
  currentCwebPriceInFiat: Scalars['Float']['output'];
  historyCwebPricesFromToday: Array<GqlCwebUsdPrice>;
  numCurrentPriceSourcesCwebUsd: Scalars['Int']['output'];
  numHistoryPriceSourcesCwebUsd: Scalars['Int']['output'];
  requestedFiat: FiatName;
  requestedNumHistoryDays: Scalars['Int']['output'];
  utcDatetimeNow: Scalars['NaiveDateTime']['output'];
};

export type GqlCwebUsdPrice = {
  __typename?: 'GqlCwebUsdPrice';
  cwebPriceInUsd: Scalars['Float']['output'];
  forUtcDate: Scalars['NaiveDate']['output'];
};

export type GqlDbClaimsPage = {
  __typename?: 'GqlDbClaimsPage';
  claims: Array<GqlIssuedClaim>;
  hasNextPage: Scalars['Boolean']['output'];
  nextPageAnchor: BlockAnchor;
};

export type GqlDbWriteOp = {
  __typename?: 'GqlDbWriteOp';
  issuedClaim: GqlIssuedClaim;
  revokeClaim: Scalars['Boolean']['output'];
};

export type GqlDbWriteOpPage = {
  __typename?: 'GqlDbWriteOpPage';
  claims: Array<GqlDbWriteOp>;
  hasNextPage: Scalars['Boolean']['output'];
  nextPageAnchor: BlockAnchor;
};

export type GqlDbWriteOpShard = {
  __typename?: 'GqlDbWriteOpShard';
  shardName: NetworkName;
  writeOp: GqlDbWriteOp;
};

export type GqlExponentParameters = {
  __typename?: 'GqlExponentParameters';
  a: Scalars['String']['output'];
  b: Scalars['String']['output'];
};

export type GqlFiatRateInfo = {
  __typename?: 'GqlFiatRateInfo';
  fiat: FiatName;
  fiatToUsd: Scalars['Float']['output'];
  numRateSources: Scalars['Int']['output'];
};

export type GqlFiatRates = {
  __typename?: 'GqlFiatRates';
  allFiatRates: Array<GqlFiatRateInfo>;
  requestedFiat: FiatName;
  requestedFiatToUsdRate: Scalars['Float']['output'];
};

export type GqlFormula = GqlExponentParameters | GqlLogarithmicParameters | GqlPolynomialParameters | GqlSigmoidParameters;

/** Graphql representation of issuance type for a custom token */
export type GqlIssuanceType = GqlMintableData | GqlNonMintableData | GqlWithMintableBondingCurve | GqlWithNonMintableBondingCurveData;

export type GqlIssuedClaim = {
  __typename?: 'GqlIssuedClaim';
  content: GqlClaim;
  issuer: Scalars['JSON']['output'];
};

export type GqlL1Fees = {
  __typename?: 'GqlL1Fees';
  embFeeInCwebPerByte: Scalars['CwebToken']['output'];
  embFeeInCwebPerTransaction: Scalars['CwebToken']['output'];
  embFeeInL1PerByte: Scalars['Float']['output'];
  embFeeInL1PerTransaction: Scalars['Float']['output'];
  l1Network: NetworkName;
  maxMissingCwebs: Scalars['CwebToken']['output'];
};

export type GqlL1Parameters = {
  __typename?: 'GqlL1Parameters';
  l1Network: NetworkName;
  /**
   * Defines maximum number of blocks to wait for L1 confirmed
   * status for a transaction
   */
  txEmbedMaxNumberOfBlocks: Scalars['Int']['output'];
  /**
   * Defines maximum number of blocks to wait after success claim
   * for a transaction to be considered as final
   */
  txMonitoredConfirmations: Scalars['Int']['output'];
  /**
   * Defines maximum number of blocks to wait for a transaction
   * success claim
   */
  txSuccessMaxNumberOfBlocks: Scalars['Int']['output'];
  /** Defines maximum number of blocks UTXO's are reserved for */
  utxoReservationMaxNumberOfBlocks: Scalars['Int']['output'];
};

export type GqlL1ShardGenesisState = {
  __typename?: 'GqlL1ShardGenesisState';
  l1Network: NetworkName;
  shardGenesisState: Scalars['JSON']['output'];
};

export type GqlLogarithmicParameters = {
  __typename?: 'GqlLogarithmicParameters';
  a: Scalars['String']['output'];
  b: Scalars['String']['output'];
};

/** Graphql representation of mintable data for a custom token */
export type GqlMintableData = {
  __typename?: 'GqlMintableData';
  /** List of minters */
  minters: Array<GqlUser>;
};

export type GqlNetworkPriceHistory = {
  __typename?: 'GqlNetworkPriceHistory';
  histL1PricesFromToday: Array<GqlNetworkPriceHistorySet>;
  numHistoryPriceSourcesCwebL1: Scalars['Int']['output'];
  numHistoryPriceSourcesL1Usd: Scalars['Int']['output'];
  requestedNetwork: NetworkName;
  requestedNumHistoryDays: Scalars['Int']['output'];
  utcDatetimeNow: Scalars['NaiveDateTime']['output'];
};

export type GqlNetworkPriceHistoryInIntervals = {
  __typename?: 'GqlNetworkPriceHistoryInIntervals';
  histL1PricesInFiatInIntervals: Array<GqlNetworkPriceHistoryInIntervalsSet>;
  numberOfIntervalsRequested: Scalars['Int']['output'];
  numberOfIntervalsReturned: Scalars['Int']['output'];
  reportedInterval: HistoryInterval;
  requestedFiat: FiatName;
  requestedHistDaysBack: Scalars['Int']['output'];
  requestedNetwork: NetworkName;
  utcDatetimeNow: Scalars['NaiveDateTime']['output'];
};

export type GqlNetworkPriceHistoryInIntervalsSet = {
  __typename?: 'GqlNetworkPriceHistoryInIntervalsSet';
  cryptoPriceInFiat: Scalars['Float']['output'];
  intervalTimestampUtc: Scalars['NaiveDateTime']['output'];
};

export type GqlNetworkPriceHistorySet = {
  __typename?: 'GqlNetworkPriceHistorySet';
  cwebPriceInL1: Scalars['Float']['output'];
  forUtcDate: Scalars['NaiveDate']['output'];
  l1PriceInCweb: Scalars['Float']['output'];
  l1PriceInUsd: Scalars['Float']['output'];
};

export type GqlNetworkPriceSet = {
  __typename?: 'GqlNetworkPriceSet';
  cwebPriceInL1: Scalars['Float']['output'];
  l1Network: NetworkName;
  l1NetworkPriceInCweb: Scalars['Float']['output'];
  l1NetworkPriceInRequestedFiat: Scalars['Float']['output'];
  numCurrentPriceSourcesCwebL1: Scalars['Int']['output'];
  numCurrentPriceSourcesL1Usd: Scalars['Int']['output'];
};

export type GqlNetworkPrices = {
  __typename?: 'GqlNetworkPrices';
  currentPrices: Array<GqlNetworkPriceSet>;
  requestedFiat: FiatName;
};

/** Graphql representation of non mintable data for a custom token */
export type GqlNonMintableData = {
  __typename?: 'GqlNonMintableData';
  /** Should be true */
  isNonMintable: Scalars['Boolean']['output'];
};

export type GqlPolynomialParameters = {
  __typename?: 'GqlPolynomialParameters';
  coefs: Array<Scalars['String']['output']>;
};

/** Graphql representation of custom token protocol fields */
export type GqlProtocolFields = {
  __typename?: 'GqlProtocolFields';
  /** Token hash id (32 bytes hax) */
  hashId: Scalars['HashId']['output'];
  /** as minial units */
  initialTokenSupply: Scalars['TokenAmount']['output'];
  /** Issuance type of the token */
  issuanceType: GqlIssuanceType;
  /** User with an admin rights */
  tokenAdmin: GqlUser;
};

export type GqlSigmoidParameters = {
  __typename?: 'GqlSigmoidParameters';
  a: Scalars['String']['output'];
};

export type GqlTokenFiatPrice = {
  __typename?: 'GqlTokenFiatPrice';
  forUtcDate: Scalars['NaiveDate']['output'];
  tokenPriceInFiat: Scalars['Float']['output'];
};

export type GqlTokenFiatPrices = {
  __typename?: 'GqlTokenFiatPrices';
  currentTokenPriceInFiat: Scalars['Float']['output'];
  historyTokenPricesFromToday: Array<GqlTokenFiatPrice>;
  requestedFiat: FiatName;
  requestedNumHistoryDays: Scalars['Int']['output'];
  utcDatetimeNow: Scalars['NaiveDateTime']['output'];
};

export type GqlTokenSupply = {
  __typename?: 'GqlTokenSupply';
  tokenSupply: Scalars['String']['output'];
};

/** Graphql representation of the information on how the transaction was authenticated */
export type GqlUser = EcdsaUser;

/** Graphql representation of mintable data for a custom token */
export type GqlWithMintableBondingCurve = {
  __typename?: 'GqlWithMintableBondingCurve';
  bondingCurveInfo: GqlBondingCurveInfo;
  /** Who can mint */
  minters: Array<GqlUser>;
};

/** Graphql representation of non mintable data for a custom token with bonding curve */
export type GqlWithNonMintableBondingCurveData = {
  __typename?: 'GqlWithNonMintableBondingCurveData';
  bondingCurveInfo: GqlBondingCurveInfo;
};

export type HeldToken = {
  __typename?: 'HeldToken';
  tokenAmount: Scalars['TokenAmount']['output'];
  tokenHash: Scalars['HashId']['output'];
};

export type HistoricalExtraFields = {
  __typename?: 'HistoricalExtraFields';
  data: Array<Field>;
  timestamp: Scalars['Int']['output'];
};

export type HistoricalPrice = {
  __typename?: 'HistoricalPrice';
  price: Scalars['CwebToken']['output'];
  timestamp: Scalars['Int']['output'];
};

export type HistoricalSupply = {
  __typename?: 'HistoricalSupply';
  supply: Scalars['TokenAmount']['output'];
  timestamp: Scalars['Int']['output'];
};

export enum HistoryInterval {
  Hour_1 = 'HOUR_1',
  Hour_24 = 'HOUR_24',
  Minute_15 = 'MINUTE_15',
  Unset = 'UNSET'
}

export type L2TransactionDataInput = {
  l2Transaction: Scalars['String']['input'];
  network: NetworkName;
};

export type LiquidityPoolPosition = {
  __typename?: 'LiquidityPoolPosition';
  cwebAmount: Scalars['CwebToken']['output'];
  tokenAmount: Scalars['TokenAmount']['output'];
  tokenHash: Scalars['HashId']['output'];
};

/** List of available blockchain networks */
export enum NetworkName {
  Arbitrum = 'ARBITRUM',
  ArbitrumTestnet = 'ARBITRUM_TESTNET',
  Bitcoin = 'BITCOIN',
  BitcoinCash = 'BITCOIN_CASH',
  BitcoinCashTestnet = 'BITCOIN_CASH_TESTNET',
  BitcoinTestnet = 'BITCOIN_TESTNET',
  Bnb = 'BNB',
  BnbTestnet = 'BNB_TESTNET',
  DevnetL1A = 'DEVNET_L1A',
  DevnetL1B = 'DEVNET_L1B',
  Dogecoin = 'DOGECOIN',
  DogecoinTestnet = 'DOGECOIN_TESTNET',
  Elrond = 'ELROND',
  ElrondTestnet = 'ELROND_TESTNET',
  Ethereum = 'ETHEREUM',
  EthereumTestnet = 'ETHEREUM_TESTNET',
  Kuji = 'KUJI',
  KujiTestnet = 'KUJI_TESTNET',
  Litecoin = 'LITECOIN',
  LitecoinTestnet = 'LITECOIN_TESTNET',
  Polygon = 'POLYGON',
  PolygonTestnet = 'POLYGON_TESTNET'
}

export type OnChain = {
  __typename?: 'OnChain';
  generalInfo: GeneralTokensInfo;
  tokenInfo: TokenInfo;
  userTokensInfo: UserTokensInfo;
};


export type OnChainTokenInfoArgs = {
  tokenHash: Scalars['HashId']['input'];
};


export type OnChainUserTokensInfoArgs = {
  pubkey: Scalars['PubKey']['input'];
};

export type PaymentConfirmation = {
  __typename?: 'PaymentConfirmation';
  memo: Scalars['UUID']['output'];
  tokenAmount: Scalars['TokenAmount']['output'];
  tokenHash: Scalars['HashId']['output'];
};

export type QueryRootWalletService = {
  __typename?: 'QueryRootWalletService';
  embedData: Array<DataResult>;
  embedStatus: Array<StatusResult>;
  getAllNetworksCurrentPrices: GqlNetworkPrices;
  getCwebPricesInFiat: GqlCwebUsdHistory;
  getFiatToUsdRates: GqlFiatRates;
  getHistoryPricesForCwebInPeriods: GqlCwebPriceHistoryInIntervals;
  getHistoryPricesForL1Network: GqlNetworkPriceHistory;
  getHistoryPricesForL1NetworkInPeriods: GqlNetworkPriceHistoryInIntervals;
  getTokenPricesInFiat: GqlTokenFiatPrices;
  /** Function returns abilities and parameters of service */
  serviceInfo: WalletServiceInfo;
  /**
   * Returns default shard if `name` is `null`. `Wallet` will use
   * default shard for writing
   */
  shard?: Maybe<WalletShardQueries>;
  shardTips: Array<BlockAnchor>;
};


export type QueryRootWalletServiceEmbedDataArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryRootWalletServiceEmbedStatusArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryRootWalletServiceGetAllNetworksCurrentPricesArgs = {
  requestedFiat: FiatName;
};


export type QueryRootWalletServiceGetCwebPricesInFiatArgs = {
  requestedFiat: FiatName;
  requestedNumHistoryDays: Scalars['Int']['input'];
};


export type QueryRootWalletServiceGetFiatToUsdRatesArgs = {
  requestedFiat: FiatName;
};


export type QueryRootWalletServiceGetHistoryPricesForCwebInPeriodsArgs = {
  requestedFiat: FiatName;
  requestedNumHistoryDays: Scalars['Int']['input'];
};


export type QueryRootWalletServiceGetHistoryPricesForL1NetworkArgs = {
  requestedNetwork: NetworkName;
  requestedNumHistoryDays: Scalars['Int']['input'];
};


export type QueryRootWalletServiceGetHistoryPricesForL1NetworkInPeriodsArgs = {
  requestedFiat: FiatName;
  requestedNetwork: NetworkName;
  requestedNumHistoryDays: Scalars['Int']['input'];
};


export type QueryRootWalletServiceGetTokenPricesInFiatArgs = {
  requestedFiat: FiatName;
  requestedNumHistoryDays: Scalars['Int']['input'];
  tokenId: Scalars['HashId']['input'];
};


export type QueryRootWalletServiceShardArgs = {
  name?: InputMaybe<NetworkName>;
  tipAnchor?: InputMaybe<BlockAnchorInput>;
};


export type QueryRootWalletServiceShardTipsArgs = {
  shards: Array<NetworkName>;
};

export type StatusResult = {
  __typename?: 'StatusResult';
  id: Scalars['ID']['output'];
  status: EmbedStatus;
  txHash?: Maybe<Scalars['ID']['output']>;
};

/**
 * Subscription must be called on every start of wallet lib
 * Notification contains filtered claims with issuer
 */
export type Subscription = {
  __typename?: 'Subscription';
  notifications: GqlDbWriteOpShard;
};


/**
 * Subscription must be called on every start of wallet lib
 * Notification contains filtered claims with issuer
 */
export type SubscriptionNotificationsArgs = {
  subscriptionsToAdd: Array<GqlClaimFilter>;
  walletKey: Scalars['PubKey']['input'];
};

export type TokenInfo = {
  __typename?: 'TokenInfo';
  /** Bonding curve for given token if exist */
  bondingCurve?: Maybe<GqlBondingCurveData>;
  /** Token extra-fields historical */
  extraFieldsHistorical: Array<HistoricalExtraFields>;
  /** Token last extra-fields */
  extraFileds: Array<Field>;
  /** L2 transaction id when the token was created */
  l2Txid: Scalars['String']['output'];
  /** Token price (it could be null if not liquidity-pool/bond-curve available) */
  price?: Maybe<Scalars['CwebToken']['output']>;
  /** Token price historical */
  priceHistorical: Array<HistoricalPrice>;
  /** Token protocol-fields */
  protocolFields: GqlProtocolFields;
  /** Token supply */
  supply: Scalars['TokenAmount']['output'];
  /** Token supply historical */
  supplyHistorical: Array<HistoricalSupply>;
};


export type TokenInfoPriceHistoricalArgs = {
  start: Scalars['Int']['input'];
};

export type UserTokensInfo = {
  __typename?: 'UserTokensInfo';
  /** List of tokens created/admin by the user */
  createdTokens: Array<Scalars['HashId']['output']>;
  /** List of tokens (hash-id) held by user and their amount */
  heldTokens: Array<HeldToken>;
  /** List of tokens the user has a liquidity pool position, plus the position (cweb amount+token amount) */
  liquidityPoolPosition: Array<LiquidityPoolPosition>;
  /** Payment confirmations to the user (amount+memo+token). (similar to how it works for cweb) */
  paymentConfirmations: Array<PaymentConfirmation>;
};

export type WalletServiceInfo = {
  __typename?: 'WalletServiceInfo';
  broadcasterWalletUrl: Scalars['String']['output'];
  /** L1 networks available for writing and fees for them and public key */
  fees: Array<GqlL1Fees>;
  genesisState: Array<GqlL1ShardGenesisState>;
  l1Parameters: Array<GqlL1Parameters>;
  /** Public key to transfer wallet fees */
  serviceWallet: Scalars['PubKey']['output'];
};

export type WalletShardQueries = {
  __typename?: 'WalletShardQueries';
  currentTip: BlockAnchor;
  fetchClaim?: Maybe<GqlClaim>;
  /** @deprecated Use fetch_claims_stable */
  fetchClaims: GqlDbClaimsPage;
  fetchClaimsStable: Array<GqlIssuedClaim>;
  /** @deprecated Use fetch_last_immutable_claims_stable */
  fetchLastImmutableClaims: GqlDbClaimsPage;
  fetchLastImmutableClaimsStable: Array<GqlIssuedClaim>;
  getOnChainData: OnChain;
  /** @deprecated This should be re-implemented to use snapshots as other methods */
  queryClaimsDiff: GqlDbWriteOpPage;
  shardName: NetworkName;
  validateBlockAnchors: Array<Scalars['Boolean']['output']>;
};


export type WalletShardQueriesFetchClaimArgs = {
  issuer: Scalars['JSON']['input'];
  key: Scalars['JSON']['input'];
};


export type WalletShardQueriesFetchClaimsArgs = {
  claimFilters: Array<GqlClaimFilter>;
  maxClaimsToReturn: Scalars['Int']['input'];
  nextPageAnchor?: InputMaybe<BlockAnchorInput>;
};


export type WalletShardQueriesFetchClaimsStableArgs = {
  claimFilters: Array<GqlClaimFilter>;
  maxClaimsToReturn: Scalars['BigInt']['input'];
  offset: Scalars['BigInt']['input'];
};


export type WalletShardQueriesFetchLastImmutableClaimsArgs = {
  claimFilters: Array<GqlClaimFilter>;
  maxClaimsToReturn: Scalars['Int']['input'];
  nextPageAnchor?: InputMaybe<BlockAnchorInput>;
};


export type WalletShardQueriesFetchLastImmutableClaimsStableArgs = {
  claimFilters: Array<GqlClaimFilter>;
  maxClaimsToReturn: Scalars['BigInt']['input'];
  offset: Scalars['BigInt']['input'];
};


export type WalletShardQueriesQueryClaimsDiffArgs = {
  claimFilters: Array<GqlClaimFilter>;
  maxClaimsToReturn: Scalars['Int']['input'];
  nextPageAnchor?: InputMaybe<BlockAnchorInput>;
};


export type WalletShardQueriesValidateBlockAnchorsArgs = {
  blockAnchors: Array<BlockAnchorInput>;
};

export type WriteMutation = {
  __typename?: 'WriteMutation';
  embed: Scalars['ID']['output'];
};


export type WriteMutationEmbedArgs = {
  data: L2TransactionDataInput;
};

export type L1BlockInfoFromClaimQueryVariables = Exact<{
  network: NetworkName;
  height: Scalars['Int']['input'];
}>;


export type L1BlockInfoFromClaimQuery = { __typename?: 'QueryRootWalletService', shard?: { __typename?: 'WalletShardQueries', fetchClaim?: { __typename?: 'GqlClaim', key: any, body: any, feesStored: any } | null } | null };


export const L1BlockInfoFromClaimDocument = gql`
    query L1BlockInfoFromClaim($network: NetworkName!, $height: Int!) {
  shard(name: $network) {
    fetchClaim(
      issuer: "L2BlockInfoProvider"
      key: {first_part: null, second_part: $height}
    ) {
      key
      body
      feesStored
    }
  }
}
    `;

/**
 * __useL1BlockInfoFromClaimQuery__
 *
 * To run a query within a React component, call `useL1BlockInfoFromClaimQuery` and pass it any options that fit your needs.
 * When your component renders, `useL1BlockInfoFromClaimQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useL1BlockInfoFromClaimQuery({
 *   variables: {
 *      network: // value for 'network'
 *      height: // value for 'height'
 *   },
 * });
 */
export function useL1BlockInfoFromClaimQuery(baseOptions: Apollo.QueryHookOptions<L1BlockInfoFromClaimQuery, L1BlockInfoFromClaimQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<L1BlockInfoFromClaimQuery, L1BlockInfoFromClaimQueryVariables>(L1BlockInfoFromClaimDocument, options);
      }
export function useL1BlockInfoFromClaimLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<L1BlockInfoFromClaimQuery, L1BlockInfoFromClaimQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<L1BlockInfoFromClaimQuery, L1BlockInfoFromClaimQueryVariables>(L1BlockInfoFromClaimDocument, options);
        }
export type L1BlockInfoFromClaimQueryHookResult = ReturnType<typeof useL1BlockInfoFromClaimQuery>;
export type L1BlockInfoFromClaimLazyQueryHookResult = ReturnType<typeof useL1BlockInfoFromClaimLazyQuery>;
export type L1BlockInfoFromClaimQueryResult = Apollo.QueryResult<L1BlockInfoFromClaimQuery, L1BlockInfoFromClaimQueryVariables>;