import "./L2TxLogCard.css";
import { memo } from "react";
import Typography from "@mui/material/Typography";
import { TxLogErrorGql } from "../../generated/graphql";
import { MOBILE_WIDTH } from "components/TableContainer/CustomizedMui";
type TxLogErrorSectionProps = {
  data: TxLogErrorGql;
  width: any;
};

const TxLogErrorSection = (props: TxLogErrorSectionProps) => {
  const { data, width } = props;

  return (
    <div
      className="L2txLogCardInfo-container"
      style={{
        marginBottom: width > MOBILE_WIDTH ? "0px" : "20px",
        marginTop: width > MOBILE_WIDTH ? "0px" : undefined,
        height: "100%",
      }}
    >
      <div className="relativeCon">
        <Typography
          className="TxLogFontBold14 absoluteRightText"
          sx={{ top: "-15px" }}
        >
          Error
        </Typography>
      </div>
      <div className="ParentsCenter-container">
        <div>
          <Typography className="BoldFont16">Error</Typography>
          <Typography className="TxLogFontMed14" gutterBottom>
            {JSON.stringify(JSON.parse(data.error), null, 4)}
          </Typography>
        </div>
      </div>
    </div>
  );
};
export default memo(TxLogErrorSection);
