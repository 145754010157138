export const trimStrings = (obj: Record<string, any>) => {
    // Base case: if obj is not an object, return it as is
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    // Iterate over each key-value pair in obj
    return Object.entries(obj).reduce((acc, [key, value]) => {
      // If value is a string and longer than 30 characters, trim it
      if (typeof value === "string" && value.length > 30) {
        acc[key] = value.slice(0, 30) + "...";
      } else {
        // If value is an object, recursively call trimStrings
        acc[key] = trimStrings(value);
      }
      return acc;
    }, {} as Record<string, any>);
  };