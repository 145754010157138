import React from "react";

// From https://github.com/lbfalvy/react-utils/blob/master/src/useArray.ts
/**
 * Ensures referential equality of an array without changed
 * dependency array length warnings.
 * @param input Array
 * @returns Array but memo'd based on its entries.
 */
export function useArray<T extends unknown[]>(input: T): T {
  const ref = React.useRef<T>(input);
  const cur = ref.current;
  if (input.length === cur.length && input.every((v, i) => v === cur[i])) {
    return cur;
  }
  return (ref.current = input);
}

export function useArrayUndefined<T extends unknown[]>(
  input: T | undefined
): T | undefined {
  const ref = React.useRef<T | undefined>(input);
  const cur = ref.current;
  if (input === cur) {
    return cur;
  }
  if (
    input &&
    cur &&
    input.length === cur.length &&
    input.every((v, i) => v === cur[i])
  ) {
    return cur;
  }
  return (ref.current = input);
}
